import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';

import api from '../../../services/api';
import './styles.css'


export default function CardRegisterMultipleUsers(props) {
  const [enterprise, setEnterprise] = useState(localStorage.getItem("enterprise" || 0));
  const history = useHistory();
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');

  async function handleSubmit(e) {
    e.preventDefault();
    console.log(data);
    console.log(data.length);
    var submitData = [];
    for (var user of data.slice(1)) {//Ignores the column name
      if (user[1].value !== ""/*Name*/ && user[2].value !== ""/*Email*/ && user[3].value !== ""/*Password*/) {
        submitData.push({
          'name': user[1].value,
          'email': user[2].value,
          'password': user[3].value,
          'username': user[1].value,
          'phone': user[4].value,
          'enterprise': enterprise,
          'groups': JSON.parse(user[5].value),
          'userRoles': JSON.parse(user[6].value),
        })
      }
    }
    console.log(submitData)
    await api.post('api/multipleusers', submitData, {
      headers: {
        token: token,
        refreshToken: refreshToken,
      }
    }).then((response) => {
      alert(`Success`);
      history.push(`admin/dashboard/${enterprise}`);
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
      }
    })
      .catch((error) => {
        if (error.response.status === 207) {
          alert(`Partial success.`)
          history.push(`admin/dashboard/${enterprise}`);
        } else if (error.response.status === 400) {
          alert(`Error registering all users.`)
        } else {
          alert(`Error.`);
          localStorage.clear();
          history.push('/');
        }
      });
  }
  const [data, setData] = useState([
    [
      { readOnly: true, value: '' },
      { value: 'Name', readOnly: true, width: '10em' },
      { value: 'Email', readOnly: true, width: '20em' },
      { value: 'Password', readOnly: true, width: '10em' },
      { value: 'Phone', readOnly: true, width: '10em' },
      { value: 'User group', readOnly: true, width: '5em' },
      { value: 'User roles', readOnly: true, width: '40em' },
    ],
    [
      { readOnly: true, value: 1 },
      { value: "John" },
      { value: "john@mail.com" },
      { value: "123456" },
      { value: "+55999999999" },
      { value: "[]" },
      { value: '{"site_viewer":[]}' },
    ],
    [
      { readOnly: true, value: 2 },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "[]" },
      { value: '{}' },
    ],
    [
      { readOnly: true, value: 3 },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "[]" },
      { value: '{}' },
    ],
    [
      { readOnly: true, value: 4 },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "[]" },
      { value: '{}' },
    ],
    [
      { readOnly: true, value: 5 },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "[]" },
      { value: '{}' },
    ],
    [
      { readOnly: true, value: 6 },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "[]" },
      { value: '{}' },
    ],

  ])

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-gray-800 text-xl font-bold">Register multiple new users</h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
              User Information
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <ReactDataSheet
                    data={data}
                    valueRenderer={cell => cell.value}
                    onCellsChanged={changes => {
                      const grid = data.map(row => [...row]);
                      changes.forEach(({ cell, row, col, value }) => {
                        grid[row][col] = { ...data[row][col], value };
                      });
                      setData(grid);
                    }}
                    parsePaste={clipboardData => {
                      return clipboardData
                        .split(/\r\n|\n|\r/)
                        .filter(row => row.length > 0)
                        .map(row => row.split("\t"));
                    }}
                    overflow="clip"
                  />
                </div>
              </div>

            </div>

            <hr className="mt-6 border-b-1 border-gray-400" />


            <div className="flex flex-wrap">
              <div className="relative w-3/12 mb-8">
                <div className="form-buttons flex mb-2">
                  <button type="reset" name="cancelButton" className="cancelButton">Cancel</button>
                </div>
              </div>
              <div className="relative w-3/12 mb-8">
                <div className="form-buttons flex mb-8">
                  <button type="submit" onClick={handleSubmit} name="submitButton" className="submitButton">Save</button>
                </div>
              </div>
            </div>


          </form>
        </div>
      </div>
    </>
  );
}
