import React from "react";
import {Link} from "react-router-dom"
import './styles.css'

export default function CardNoEnterpriseDashboard() {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className=" items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h1>Select an enterprise or <Link className="link" to="/admin/register_enterprise" ><u>click here</u></Link> to create a new enterprise.</h1>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          <div className="relative h-350-px">
          </div>
        </div>
      </div>
    </>
  );
}
