import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useHistory, useLocation } from 'react-router-dom';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { FormControl } from 'baseui/form-control';
import makeAnimated from 'react-select/animated';

import { RadioGroup, Radio, ALIGN } from 'baseui/radio';

import api from '../../../services/api';
import './styles.css'


export default function CardUserDetails(props) {
  const [selectedUser, setSelectedUser] = useState(props.selectedUser)
  const [enterprise, setEnterprise] = useState(localStorage.getItem('enterprise') || 0);
  const [isSelectedUserDisabled, setIsSelectedUserDisabled] = useState(-1)

  const animatedComponents = makeAnimated();
  let location = useLocation();
  const history = useHistory();
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');

  const [editUserEmail, setEditUserEmail] = useState("");
  const [editUserName, setEditUserName] = useState("");
  const [editUserUserGroups, setEditUserUserGroups] = useState({});
  const [editUserPhone, setEditUserPhone] = useState("");
  const [editUserPassword, setEditUserPassword] = useState("");

  const [arraySiteManager, setArraySiteManager] = useState([]);
  const [arrayAreaManager, setArrayAreaManager] = useState([]);
  const [arrayLineManager, setArrayLineManager] = useState([]);

  const [arraySiteOperator, setArraySiteOperator] = useState([]);
  const [arrayAreaOperator, setArrayAreaOperator] = useState([]);
  const [arrayLineOperator, setArrayLineOperator] = useState([]);

  const [arraySiteViewer, setArraySiteViewer] = useState([]);
  const [arrayAreaViewer, setArrayAreaViewer] = useState([]);
  const [arrayLineViewer, setArrayLineViewer] = useState([]);

  const [arraySiteOverviewViewer, setArraySiteOverviewViewer] = useState([]);
  const [arrayAreaOverviewViewer, setArrayAreaOverviewViewer] = useState([]);
  const [arrayLineOverviewViewer, setArrayLineOverviewViewer] = useState([]);

  const [isManager, setIsManager] = useState("0");
  const [isSiteManager, setIsSiteManager] = useState("0");
  const [isAreaManager, setIsAreaManager] = useState("0");
  const [isLineManager, setIsLineManager] = useState("0");

  const [isOperator, setIsOperator] = useState("0");
  const [isSiteOperator, setIsSiteOperator] = useState("0");
  const [isAreaOperator, setIsAreaOperator] = useState("0");
  const [isLineOperator, setIsLineOperator] = useState("0");

  const [isViewer, setIsViewer] = useState("0");
  const [isSiteViewer, setIsSiteViewer] = useState("0");
  const [isAreaViewer, setIsAreaViewer] = useState("0");
  const [isLineViewer, setIsLineViewer] = useState("0");

  const [isOverviewer, setIsOverviewer] = useState("0");
  const [isSiteOverviewer, setIsSiteOverviewer] = useState("0");
  const [isAreaOverviewer, setIsAreaOverviewer] = useState("0");
  const [isLineOverviewer, setIsLineOverviewer] = useState("0");

  const [siteManager, setSiteManager] = useState([]);
  const [siteOperator, setSiteOperator] = useState([]);
  const [siteViewer, setSiteViewer] = useState([]);
  const [siteOverviewer, setSiteOverviewer] = useState([]);

  const [areaManager, setAreaManager] = useState([]);
  const [areaOperator, setAreaOperator] = useState([]);
  const [areaViewer, setAreaViewer] = useState([]);
  const [areaOverviewer, setAreaOverviewer] = useState([]);

  const [lineManager, setLineManager] = useState([]);
  const [lineOperator, setLineOperator] = useState([]);
  const [lineViewer, setLineViewer] = useState([]);
  const [lineOverviewer, setLineOverviewer] = useState([]);

  const [editing, setEditing] = useState(false);

  useEffect(() => {
    //#region Sites
    if (selectedUser && selectedUser.user_roles && selectedUser.user_roles.site_manager) {
      setArraySiteManager(selectedUser.user_roles.site_manager || []);
    }
    if (selectedUser && selectedUser.user_roles && selectedUser.user_roles.site_operator) {
      setArraySiteOperator(selectedUser.user_roles.site_operator || []);
    }
    if (selectedUser && selectedUser.user_roles && selectedUser.user_roles.site_viewer) {
      setArraySiteViewer(selectedUser.user_roles.site_viewer || []);
    }
    if (selectedUser && selectedUser.user_roles && selectedUser.user_roles.site_overview_viewer) {
      setArraySiteOverviewViewer(selectedUser.user_roles.site_overview_viewer || []);
    }
    //#endregion
    //#region Areas
    if (selectedUser && selectedUser.user_roles && selectedUser.user_roles.area_manager) {
      setArrayAreaManager(selectedUser.user_roles.area_manager || []);
    }
    if (selectedUser && selectedUser.user_roles && selectedUser.user_roles.area_operator) {
      setArrayAreaOperator(selectedUser.user_roles.area_operator || []);
    }
    if (selectedUser && selectedUser.user_roles && selectedUser.user_roles.area_viewer) {
      setArrayAreaViewer(selectedUser.user_roles.area_viewer || []);
    }
    if (selectedUser && selectedUser.user_roles && selectedUser.user_roles.area_overview_viewer) {
      setArrayAreaOverviewViewer(selectedUser.user_roles.area_overview_viewer || []);
    }
    //#endregion
    //#region Lines
    if (selectedUser && selectedUser.user_roles && selectedUser.user_roles.line_manager) {
      setArrayLineManager(selectedUser.user_roles.line_manager || []);
    }
    if (selectedUser && selectedUser.user_roles && selectedUser.user_roles.line_operator) {
      setArrayLineOperator(selectedUser.user_roles.line_operator || []);
    }
    if (selectedUser && selectedUser.user_roles && selectedUser.user_roles.line_viewer) {
      setArrayLineViewer(selectedUser.user_roles.line_viewer || []);
    }
    if (selectedUser && selectedUser.user_roles && selectedUser.user_roles.line_overview_viewer) {
      setArrayLineOverviewViewer(selectedUser.user_roles.line_overview_viewer || []);
    }
    //#endregion
  }, [selectedUser])

  useEffect(() => {
    if (arraySiteManager) {
      var temp = [];
      arraySiteManager.forEach((selected_site, index) => {
        const auxiliar = sitesOptions.find(site => site.value === selected_site)
        if (auxiliar) {
          temp.push(auxiliar)
        } else {
          temp.push({ value: selected_site, label: `Unknown site: ${selected_site.toString()}` })
        }
      })
    }
    setSiteManager(temp);
  }, [arraySiteManager])

  useEffect(() => {
    console.log(arraySiteOperator)
    if (arraySiteOperator) {
      var temp = [];
      arraySiteOperator.forEach((selected_site, index) => {
        const auxiliar = sitesOptions.find(site => site.value === selected_site)
        if (auxiliar) {
          temp.push(auxiliar)
        } else {
          temp.push({ value: selected_site, label: `Unknown site: ${selected_site}` })
        }
      })
    }
    setSiteOperator(temp);
  }, [arraySiteOperator])

  useEffect(() => {
    if (arraySiteViewer) {
      var temp = [];
      arraySiteViewer.forEach((selected_site, index) => {
        const auxiliar = sitesOptions.find(site => site.value === selected_site)
        if (auxiliar) {
          temp.push(auxiliar)
        } else {
          temp.push({ value: selected_site, label: `Unknown site: ${selected_site}` })
        }
      })
    }
    setSiteViewer(temp);
  }, [arraySiteViewer])

  // useEffect(() => {
  //   if (arraySiteOverviewer) {
  //     var temp=[];
  //     arraySiteOverviewer.forEach((selected_site, index) => {
  //       const auxiliar = sitesOptions.find(site => site.value === selected_site)
  //       if (auxiliar) {
  //         temp.push(auxiliar)
  //       }else{
  //   temp.push({value:selected_site, label:`Unknown site: ${selected_site}`})
  // }
  //     })
  //   }
  //   setSiteOverviewer(temp);
  // }, [arraySiteOverviewer])







  useEffect(() => {
    if (arrayAreaManager) {
      console.log(arrayAreaManager)
      var temp = [];
      arrayAreaManager.forEach((selected_area, index) => {
        // var auxiliar = areasOptions.filter(site => (
        //   arraySiteManager === null ||
        //   (arraySiteManager !== null && !check_if_site_of_the_area_is_selected(arraySiteManager, site.id_site))
        // ));
        var auxiliar = [];
        for (var site of areasOptions) {
          for (var area of site.options) {
            console.log(area, selected_area)
            if (area.value === selected_area) {
              auxiliar.push(area)
            }
          }
        }
        console.log(auxiliar.length);
        if (auxiliar.length > 0) {
          temp = temp.concat(auxiliar);
        } else {
          temp.push({ value: parseInt(selected_area), label: `Unknown area: ${selected_area}` })
        }
      })
      setAreaManager(temp);
    }
  }, [arrayAreaManager])

  useEffect(() => {
    if (arrayAreaOperator) {
      var temp = [];
      arrayAreaOperator.forEach((selected_area, index) => {
        // var auxiliar = areasOptions.filter(site => (
        //   arraySiteOperator === null ||
        //   (arraySiteOperator !== null && !check_if_site_of_the_area_is_selected(arraySiteOperator, site.id_site))
        // ));
        var auxiliar = [];
        for (var site of areasOptions) {
          for (var area of site.options) {
            if (area.value === selected_area) {
              auxiliar.push(area)
            }
          }
        }
        if (auxiliar.length > 0) {
          temp = temp.concat(auxiliar);
        } else {
          temp.push({ value: selected_area, label: `Unknown area: ${selected_area}` })
        }
      })
      setAreaOperator(temp);
      console.log(temp)
    }
  }, [arrayAreaOperator])

  useEffect(() => {
    if (arrayAreaViewer) {
      var temp = [];
      arrayAreaViewer.forEach((selected_area, index) => {
        // var auxiliar = areasOptions.filter(site => (
        //   arraySiteViewer === null ||
        //   (arraySiteViewer !== null && !check_if_site_of_the_area_is_selected(arraySiteViewer, site.id_site))
        // ));
        var auxiliar = [];
        for (var site of areasOptions) {
          for (var area of site.options) {
            if (area.value === selected_area) {
              auxiliar.push(area)
            }
          }
        }
        if (auxiliar.length > 0) {
          temp = temp.concat(auxiliar);
        } else {
          temp.push({ value: selected_area, label: `Unknown area: ${selected_area}` })
        }
      })
      setAreaViewer(temp);
      console.log(temp)
    }
  }, [arrayAreaViewer])

  // useEffect(() => {
  //   if (arrayAreaOverviewer) {
  //     var temp = [];
  //     arrayAreaOverviewer.forEach((selected_area, index) => {
  //       // var auxiliar = areasOptions.filter(site => (
  //       //  arraySiteOverviewer === null ||
  //       //  (arraySiteOverviewer !== null && !check_if_site_of_the_area_is_selected(arraySiteOverviewer, site.id_site))
  //       // ));
  //       var auxiliar = [];
  //       for (var site of areasOptions) {
  //         for (var area of site.options) {
  //           if (area.value === selected_area) {
  //             auxiliar.push(area)
  //           }
  //         }
  // if (auxiliar.length > 0) {
  //   temp = temp.concat(auxiliar);
  // } else {
  //   temp.push({ value: selected_area, label: `Unknown area: ${selected_area}` })
  // }
  //       }
  //     })
  //     setAreaOverviewer(temp);
  //     console.log(temp)
  //   }
  // }, [arrayAreaOverviewer])





  useEffect(() => {
    if (arrayLineManager) {
      var temp = [];
      arrayLineManager.forEach((selected_line, index) => {
        // var auxiliar = linesOptions.filter(area => (
        //   arraySiteManager === null ||
        //   (arraySiteManager !== null && !check_if_line_is_selected(arraySiteManager, arrayAreaManager, area.id_area, area.id_site))
        // ));
        for (var site of linesOptions) {
          for (var line of site.options) {
            if (line.value === selected_line) {
              temp.push(line)
            }
          }
        }
      })
      setLineManager(temp);
      console.log(temp)
    }
  }, [arrayLineManager])

  useEffect(() => {
    if (arrayLineOperator) {
      var temp = [];
      arrayLineOperator.forEach((selected_line, index) => {
        // var auxiliar = linesOptions.filter(area => (
        //   arraySiteOperator === null ||
        //   (arraySiteOperator !== null && !check_if_line_is_selected(arraySiteOperator, arrayAreaOperator, area.id_area, area.id_site))
        // ));
        for (var site of linesOptions) {
          for (var line of site.options) {
            if (line.value === selected_line) {
              temp.push(line)
            }
          }
        }
      })
      setLineOperator(temp);
    }
  }, [arrayLineOperator])

  useEffect(() => {
    if (arrayLineViewer) {
      var temp = [];
      console.log(arrayLineViewer);
      console.log(linesOptions);
      arrayLineViewer.forEach((selected_line, index) => {
        // var auxiliar = linesOptions.filter(area => (
        //   arraySiteViewer === null ||
        //   (arraySiteViewer !== null && !check_if_line_is_selected(arraySiteViewer, arrayAreaViewer, area.id_area, area.id_site))
        // ));
        for (var site of linesOptions) {
          for (var line of site.options) {
            if (line.value === selected_line) {
              temp.push(line)
            }
          }
        }
      })
      setLineViewer(temp);
      console.log(temp)
    }
  }, [arrayLineViewer])

  useEffect(() => {
    if (arrayLineOverviewViewer) {
      var temp = [];
      console.log(arrayLineOverviewViewer);
      console.log(linesOptions);
      arrayLineOverviewViewer.forEach((selected_line, index) => {
        // var auxiliar = linesOptions.filter(area => (
        //   arraySiteOverviewer === null ||
        //   (arraySiteOverviewer !== null && !check_if_line_is_selected(arraySiteOverviewer, arrayAreaOverviewer, area.id_area, area.id_site))
        // ));
        for (var site of linesOptions) {
          for (var line of site.options) {
            if (line.value === selected_line) {
              temp.push(line)
            }
          }
        }
      })
      setLineOverviewer(temp);
      console.log(temp)
    }
  }, [arrayLineOverviewViewer])



  function check_if_site_is_selected(array_selected_sites, site) {
    for (var i in array_selected_sites) {
      if (array_selected_sites[i].value === site.toString()) {
        return true;
      }
    }
    return false;
  }

  function check_if_site_of_the_area_is_selected(array_selected_sites, site) {
    for (var i in array_selected_sites) {
      if (array_selected_sites[i].value === site) {
        return true;
      }
    }
    return false;
  }

  function check_if_line_is_selected(array_selected_sites, array_selected_areas, area, site) {
    for (var i in array_selected_sites) {
      if (array_selected_sites[i].value === site) {
        return true;
      }
    }
    for (var j in array_selected_areas) {
      if (array_selected_areas[j].value === area) {
        return true;
      }
    }
    return false;
  }



  if (props.selectedUser !== selectedUser) {
    setSelectedUser(props.selectedUser)
  }

  function handleEditButton() {
    setEditing(true);
    props.isEditing(true);
  }
  async function handleSaveButton(e) {
    console.log('Save');
    // console.log(sitesOptions);
    e.preventDefault();
    var phone = "";
    if (editUserPhone !== undefined) {
      phone = editUserPhone;
    }

    function convertJsonArray_to_ValueArray(JsonArray) {
      if (JsonArray !== null) {
        return JsonArray.map(element => element.value);
      }
      return [];
    }

    if (editUserEmail === "" || editUserName === "" || enterprise === 0) {
      alert('Preencha todos os dados obrigatórios.');
    } else {
      console.log(arraySiteManager);
      const data = {
        'uid': selectedUser.id_user_firebase,
        'name': editUserName,
        'email': editUserEmail,
        'password': editUserPassword,
        'phone': phone,
        'enterprise': enterprise,
        'groups': [editUserUserGroups],
        'site_manager': arraySiteManager,
        'area_manager': arrayAreaManager,
        'line_manager': arrayLineManager,
        'site_operator': arraySiteOperator,
        'area_operator': arrayAreaOperator,
        'line_operator': arrayLineOperator,
        'site_viewer': arraySiteViewer,
        'area_viewer': arrayAreaViewer,
        'line_viewer': arrayLineViewer,
        'overviewer': arrayLineOverviewViewer,
      };

      console.log(data);

      try {
        await api.put('api/users', data, {
          headers: {
            token: token,
            refreshToken: refreshToken,
          }
        })
          .then((response) => {
            alert(`Usuário editado com sucesso`);
            history.push(`admin/dashboard/${enterprise}`);
            if (response.data.token) {
              localStorage.setItem('token', response.data.token);
            }
          })
          .catch((error) => {
            if (error.response.status === 401 || error.response.status === 400) {
              localStorage.clear();
              history.push('/');
            }
          })
      } catch (err) {
        alert('Erro no cadastro, tente novamente.');
      }
    }
  }
  function handleCancelButton() {
    setEditing(false);
  }

  if (props.selectedUser !== selectedUser) {
    setSelectedUser(props.selectedUser);
    setEditing(false);
  }

  async function handleEnableButton(e) {
    const data = [{
      'enterprise': enterprise,
      'uid': selectedUser.id_user_firebase,
    }];

    try {
      await api.put('api/users/enable', data, {
        headers: {
          token: token,
          refreshToken: refreshToken,
        }
      })
        .then((response) => {
          alert(`User enabled.`);
          setIsSelectedUserDisabled(0);
          // history.push(`admin/dashboard/${enterprise}`);
          if (response.data.token) {
            localStorage.setItem('token', response.data.token);
          }
        })
        .catch((error) => {
          if (error.response.status === 401 || error.response.status === 400) {
            localStorage.clear();
            history.push('/');
          } else if (error.response.status === 403) {
            alert(`Error enabling user.`)
          }
        })
    } catch (err) {
      alert('Error enabling user.');
    }
  }

  async function handleDisableButton(e) {
    const data = [{
      'enterprise': enterprise,
      'uid': selectedUser.id_user_firebase,
    }];

    try {
      await api.put('api/users/disable', data, {
        headers: {
          token: token,
          refreshToken: refreshToken,
        }
      })
        .then((response) => {
          console.log("oi")
          alert(`User disabled.`);
          // history.push(`admin/dashboard/${enterprise}`);
          setIsSelectedUserDisabled(1);
          if (response.data.token) {
            localStorage.setItem('token', response.data.token);
          }
        })
        .catch((error) => {
          if (error.response.status === 401 || error.response.status === 400) {
            localStorage.clear();
            history.push('/');
          } else if (error.response.status === 403) {
            alert(`Error disabling user.`)
          }
        })
    } catch (err) {
      alert('Error disabling user.');
    }
  }



  useEffect(() => {
    if (!editing) {
      if (selectedUser !== null) {
        console.log(selectedUser)
        setEditUserEmail(selectedUser.user_email);
        setEditUserName(selectedUser.user_name);
        setEditUserPhone(selectedUser.phone_number);
        if(selectedUser.groups){
          setEditUserUserGroups(selectedUser.groups[0]);  
        }
        else{
          setEditUserUserGroups(null);
        }

        setIsManager((props.selectedUser.user_roles)
          &&
          (
            (
              props.selectedUser.user_roles.site_manager
              && props.selectedUser.user_roles.site_manager.length > 0
            )
            ||
            (
              props.selectedUser.user_roles.area_manager
              && props.selectedUser.user_roles.area_manager.length > 0
            )
            ||
            (
              props.selectedUser.user_roles.line_manager
              && props.selectedUser.user_roles.line_manager.length > 0
            )
          )
          ? "1" : "0");

        setIsSiteManager(
          (props.selectedUser.user_roles)
            &&
            (
              (
                props.selectedUser.user_roles.site_manager
                && props.selectedUser.user_roles.site_manager.length > 0
              )
            )
            ? "1" : "0");


        setIsAreaManager(
          (props.selectedUser.user_roles)
            &&
            (
              (
                props.selectedUser.user_roles.area_manager
                && props.selectedUser.user_roles.area_manager.length > 0
              )
            )
            ? "1" : "0");



        setIsLineManager(
          (props.selectedUser.user_roles)
            &&
            (
              (
                props.selectedUser.user_roles.line_manager
                && props.selectedUser.user_roles.line_manager.length > 0
              )
            )
            ? "1" : "0");


        setIsOperator(
          (props.selectedUser.user_roles)
            &&
            (
              (
                props.selectedUser.user_roles.site_operator
                && props.selectedUser.user_roles.site_operator.length > 0
              )
              ||
              (
                props.selectedUser.user_roles.area_operator
                && props.selectedUser.user_roles.area_operator.length > 0
              )
              ||
              (
                props.selectedUser.user_roles.line_operator
                && props.selectedUser.user_roles.line_operator.length > 0
              )
            )
            ? "1" : "0");

        setIsSiteOperator(
          (props.selectedUser.user_roles)
            &&
            (
              (
                props.selectedUser.user_roles.site_operator
                && props.selectedUser.user_roles.site_operator.length > 0
              )
            )
            ? "1" : "0");

        setIsAreaOperator(
          (props.selectedUser.user_roles)
            &&
            (
              (
                props.selectedUser.user_roles.area_operator
                && props.selectedUser.user_roles.area_operator.length > 0
              )
            )
            ? "1" : "0");

        setIsLineOperator(
          (props.selectedUser.user_roles)
            &&
            (
              (
                props.selectedUser.user_roles.line_operator
                && props.selectedUser.user_roles.line_operator.length > 0
              )
            )
            ? "1" : "0");



        setIsViewer(
          (props.selectedUser.user_roles)
            &&
            (
              (
                props.selectedUser.user_roles.site_viewer
                && props.selectedUser.user_roles.site_viewer.length > 0
              )
              ||
              (
                props.selectedUser.user_roles.area_viewer
                && props.selectedUser.user_roles.area_viewer.length > 0
              )
              ||
              (
                props.selectedUser.user_roles.line_viewer
                && props.selectedUser.user_roles.line_viewer.length > 0
              )
            )
            ? "1" : "0");

        setIsSiteViewer(
          (props.selectedUser.user_roles)
            &&
            (
              (
                props.selectedUser.user_roles.site_viewer
                && props.selectedUser.user_roles.site_viewer.length > 0
              )
            )
            ? "1" : "0");

        setIsAreaViewer(
          (props.selectedUser.user_roles)
            &&
            (
              (
                props.selectedUser.user_roles.area_viewer
                && props.selectedUser.user_roles.area_viewer.length > 0
              )
            )
            ? "1" : "0");

        setIsLineViewer(
          (props.selectedUser.user_roles)
            &&
            (
              (
                props.selectedUser.user_roles.line_viewer
                && props.selectedUser.user_roles.line_viewer.length > 0
              )
            )
            ? "1" : "0");


        setIsOverviewer(
          (props.selectedUser.user_roles)
            &&
            (
              (
                props.selectedUser.user_roles.site_overview_viewer
                && props.selectedUser.user_roles.site_overview_viewer.length > 0
              )
            )
            ? "1" : "0");
      }
    }
  }, [selectedUser, editing])














  const [sitesOptions, setSitesOptions] = useState([]);
  const [areasOptions, setAreasOptions] = useState([]);
  const [linesOptions, setLinesOptions] = useState([]);
  const [userGroupOptions, setUserGroupOptions] = useState([]);
  const [allEnterpriseData, setAllEnterpriseData] = useState({});

  // useEffect(() => {
  //   console.log(arraySiteManager)
  //   var temp = [];
  //   for (var site_id of arraySiteManager) {
  //     temp.push(sitesOptions.find(site => site.value === site_id.toString()))
  //   }
  //   // console.log(temp)
  //   // console.log(siteManagerSelectedOptions)
  //   // if(temp!==siteManagerSelectedOptions){
  //   //   setSiteManagerSelectedOptions(temp);
  //   // }
  // }, [sitesOptions, arraySiteManager])

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );



  //#region  Loads all the data

  useEffect(() => {
    api.get(
      `api/enterprises/group/${enterprise}`, {
      headers: {
        token: token,
        refreshToken: refreshToken,
      }
    }).then(response => {
      setUserGroupOptions(response.data.map(userGroup => ({ value: userGroup.id_user_group, label: userGroup.cd_user_group })));
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
      }
    }).catch((error) => {
      console.log("erro")
      console.log(error.response)
      if (error.response.status === 401 || error.response.status === 400) {
        localStorage.clear();
        history.push('/');
      }
    })
  }, [location, history, enterprise, refreshToken, token]);

  useEffect(() => {
    api.get(
      `api/enterprises/all/${enterprise}`, {
      headers: {
        token: token,
        refreshToken: refreshToken,
      }
    }).then(response => {
      setAllEnterpriseData(response);
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
      }
    }).catch((error) => {
      console.log("erro")
      console.log(error.response)
      if (error.response.status === 401 || error.response.status === 400) {
        localStorage.clear();
        history.push('/');
      }
    })
  }, [location, history, enterprise, refreshToken, token]);

  useEffect(() => {
    if (allEnterpriseData.data !== undefined) {
      setSitesOptions(allEnterpriseData.data.map(site => ({ value: parseInt(site.id_site), label: site.nm_site })));
    }
  }, [allEnterpriseData, location]);

  useEffect(() => {
    if (allEnterpriseData.data !== undefined) {
      setAreasOptions(allEnterpriseData.data.map(site => ({ label: site.nm_site, id_site: parseInt(site.id_site), options: site.areas.map(area => ({ value: parseInt(area.id_area), label: `${site.nm_site}/${area.nm_area}` })) })));
    }
  }, [allEnterpriseData, location]);

  useEffect(() => {
    if (allEnterpriseData.data !== undefined) {
      var temp = allEnterpriseData.data.map(site => (site.areas.map(area => ({ label: area.nm_area, id_site: parseInt(site.id_site), id_area: parseInt(area.id_area), options: area.lines.map(line => ({ value: parseInt(line.id_equipment), label: `${site.nm_site}/${area.nm_area}/${line.nm_equipment}` })) }))));
      var i;
      var test_list = [];
      for (i of temp) {
        test_list = test_list.concat(i)
      }
      setLinesOptions(test_list);
    }
  }, [allEnterpriseData, location]);

  //Updates array when any answer is changed to "0"
  useEffect(() => {
    if (!isManager) {
      setArraySiteManager([]);
      setArrayAreaManager([]);
      setArrayLineManager([]);
    }

    if (!isOperator) {
      setArraySiteOperator([]);
      setArrayAreaOperator([]);
      setArrayLineOperator([]);
    }

    if (!isViewer) {
      setArraySiteViewer([]);
      setArrayAreaViewer([]);
      setArrayLineViewer([]);
    }

    if (!isSiteManager) {
      setArraySiteManager([]);
    }
    if (!isAreaManager) {
      setArrayAreaManager([]);
    }
    if (!isLineManager) {
      setArrayLineManager([]);
    }

    if (!isSiteOperator) {
      setArraySiteOperator([]);
    }
    if (!isAreaOperator) {
      setArrayAreaOperator([]);
    }
    if (!isLineOperator) {
      setArrayLineOperator([]);
    }

    if (!isSiteViewer) {
      setArraySiteViewer([]);
    }
    if (!isAreaViewer) {
      setArrayAreaViewer([]);
    }
    if (!isLineViewer) {
      setArrayLineViewer([]);
    }

    if (!isOverviewer) {
      setArrayLineOverviewViewer([]);
    }
  }, [isManager, isOperator, isViewer, isOverviewer, isSiteManager, isAreaManager, isLineManager, isSiteOperator, isAreaOperator, isLineOperator, isSiteViewer, isAreaViewer, isLineViewer, token, refreshToken, location])

  useEffect(() => {
    async function checkIfEnabled() {
      try {
        await api.get(`api/user/enabled/${selectedUser.id_user_firebase}`, {
          headers: {
            token: token,
            refreshToken: refreshToken,
          }
        })
          .then((response) => {
            console.log(response.data)
            if (response.data.disabled == true) {
              setIsSelectedUserDisabled(1);
            } else {
              setIsSelectedUserDisabled(0);
            }
            if (response.data.token) {
              localStorage.setItem('token', response.data.token);
            }
          })
          .catch((error) => {
            if (error.response.status === 401 || error.response.status === 400) {
              localStorage.clear();
              history.push('/');
            }
          })
      } catch (err) {

      }
    }
    checkIfEnabled();
  }, [selectedUser])

  //#endregion

  const managerSection =
    <div className="flex flex-wrap">
      <div className="w-full lg:w-4/12 px-4">
        <div className="relative w-full mb-3">
          {/* <div className="isManager"> */}
          {/* <div className="is-manager-question"> */}
          <FormControl
            className="is-manager-question-content"
            label="Is this user a manager?"
            row={true}

          // caption="RadioGroup caption"
          >
            <RadioGroup
              value={isManager}
              onChange={event => setIsManager(event.currentTarget.value)}
              align={ALIGN.horizontal}
              readOnly={!editing}
              disabled={!editing}
            >
              <Radio value="1">Yes</Radio>
              <Radio value="0">No</Radio>
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      <div className="w-full lg:w-8/12 px-4" hidden={!(isManager == true)}>
        <div className="relative w-full mb-3">
          <div className="flex flex-wrap">

            {/* <div className="manager-categories" hidden={!(isManager == true)}> */}
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">

                {/* <div className="div-site-manager-row"> */}
                {/* <div className="isSiteManager"> */}
                <FormControl
                  label="Is this user a site manager?"
                // caption="RadioGroup caption"
                >
                  <RadioGroup
                    value={isSiteManager}
                    onChange={event => setIsSiteManager(event.currentTarget.value)}
                    align={ALIGN.horizontal}
                    readOnly={!editing}
                    disabled={!editing}
                  >
                    <Radio value="1">Yes</Radio>
                    <Radio value="0">No</Radio>
                  </RadioGroup>
                </FormControl>
                {/* </div> */}
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4" hidden={!(isSiteManager === "1")}>
              <div className="relative w-full mb-3">
                {/* <div className="site-manager" hidden={!(isSiteManager === "1")}> */}
                <label htmlFor="field1">
                  <span>Select the sites <span className="required">*</span></span>
                  <Select className="dropdown-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={[]}
                    isMulti
                    options={sitesOptions}
                    value={siteManager}
                    onChange={(selectedOption) => {
                      var temp = [];
                      if (selectedOption) {
                        selectedOption.forEach((selectedItem) => {
                          temp.push(selectedItem.value)
                        })
                      }
                      console.log(temp)
                      setArraySiteManager(temp);
                    }}
                    readOnly={!editing}
                    isDisabled={!editing}
                  />
                </label>
              </div>
            </div>

          </div>

          <div className="flex flex-wrap">

            {/* <div className="div-area-manager-row">
              <div className="isAreaManager"> */}
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <FormControl
                  label="Is this user an area manager?"
                // caption="RadioGroup caption"
                >
                  <RadioGroup
                    value={isAreaManager}
                    onChange={event => setIsAreaManager(event.currentTarget.value)}
                    align={ALIGN.horizontal}
                    readOnly={!editing}
                    disabled={!editing}
                  >
                    <Radio value="1">Yes</Radio>
                    <Radio value="0">No</Radio>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4" hidden={!(isAreaManager === "1")}>
              <div className="relative w-full mb-3">
                {/* <div className="area-manager-area-selection" hidden={!(isAreaManager === "1")}> */}
                <label htmlFor="field1"><span>Select the areas <span className="required">*</span></span>
                  <Select className="dropdown-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={[]}
                    value={areaManager}
                    isMulti
                    // options={areasOptions.filter(site => (arraySiteManager === null || (arraySiteManager !== null && !arraySiteManager.includes([site.id_site]))))}
                    options={areasOptions.filter(site => (arraySiteManager === null || (arraySiteManager !== null && !check_if_site_of_the_area_is_selected(arraySiteManager, site.id_site))))}
                    // options={areasOptions}
                    formatGroupLabel={formatGroupLabel}
                    onChange={(selectedOption) => {
                      var temp = [];
                      if (selectedOption) {
                        selectedOption.forEach((selectedItem) => {
                          temp.push(selectedItem.value)
                        })
                      }
                      setArrayAreaManager(temp);
                    }}
                    readOnly={!editing}
                    isDisabled={!editing}
                  />
                </label>
                {/* </div> */}
              </div>
            </div>
          </div>


          <div className="flex flex-wrap">
            {/* <div className="div-area-manager-row"> */}
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                {/* <div className="isLineManager"> */}
                <FormControl
                  label="Is this user a line manager?"
                >
                  <RadioGroup
                    value={isLineManager}
                    onChange={event => setIsLineManager(event.currentTarget.value)}
                    align={ALIGN.horizontal}
                    readOnly={!editing}
                    disabled={!editing}
                  >
                    <Radio value="1">Yes</Radio>
                    <Radio value="0">No</Radio>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <div className="line-manager-site-selection" hidden={!(isLineManager === "1")}>
                  <label htmlFor="field1"><span>Select the lines <span className="required">*</span></span>
                    <Select className="dropdown-selection"
                      // closeMenuOnSelect={true}
                      components={animatedComponents}
                      defaultValue={[]}
                      isMulti
                      // options={areasOptions.filter(site => (arraySiteManager !== null && !arraySiteManager.includes([site.label])))}
                      options={linesOptions.filter(site => (arraySiteManager === null || (arraySiteManager !== null && !check_if_line_is_selected(arraySiteManager, arrayAreaManager, site.id_area, site.id_site))))}
                      value={lineManager}
                      // options={linesOptions}
                      formatGroupLabel={formatGroupLabel}
                      onChange={(selectedOption) => {
                        var temp = [];
                        if (selectedOption) {
                          selectedOption.forEach((selectedItem) => {
                            temp.push(selectedItem.value)
                          })
                        }
                        setArrayLineManager(temp);
                      }}
                      readOnly={!editing}
                      isDisabled={!editing}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div>

  const operatorSection =
    <div className="flex flex-wrap">
      <div className="w-full lg:w-4/12 px-4">
        <div className="relative w-full mb-3">
          {/* <div className="isOperator">
      <div className="is-operator-question"> */}
          <FormControl
            className="is-operator-question-content"
            label="Is this user an operator?"
          // caption="RadioGroup caption"
          >
            <RadioGroup
              value={isOperator}
              onChange={event => setIsOperator(event.currentTarget.value)}
              align={ALIGN.horizontal}
              readOnly={!editing}
              disabled={!editing}
            >
              <Radio value="1">Yes</Radio>
              <Radio value="0">No</Radio>
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      <div className="w-full lg:w-8/12 px-4" hidden={!(isOperator == true)}>
        <div className="relative w-full mb-3">
          <div className="flex flex-wrap">

            {/* <div className="operator-categories" hidden={!(isOperator === "1")}> */}
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">



                {/* <div className="div-site-operator-row">
                  <div className="isSiteOperator"> */}
                <FormControl
                  label="Is this user a site operator?"
                // caption="RadioGroup caption"
                >
                  <RadioGroup
                    value={isSiteOperator}
                    onChange={event => setIsSiteOperator(event.currentTarget.value)}
                    align={ALIGN.horizontal}
                    readOnly={!editing}
                    disabled={!editing}
                  >
                    <Radio value="1">Yes</Radio>
                    <Radio value="0">No</Radio>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="w-full lg:w-6/12 px-4" hidden={!(isSiteOperator === "1")}>
              <div className="relative w-full mb-3">
                {/* <div className="site-operator" hidden={!(isSiteOperator === "1")}> */}

                <label htmlFor="field1">
                  <span>Select the sites <span className="required">*</span></span>
                  <Select className="dropdown-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={[]}
                    isMulti
                    options={sitesOptions}
                    value={siteOperator}
                    onChange={(selectedOption) => {
                      var temp = [];
                      if (selectedOption) {
                        selectedOption.forEach((selectedItem) => {
                          temp.push(selectedItem.value)
                        })
                      }
                      setArraySiteOperator(temp);
                    }}
                    readOnly={!editing}
                    isDisabled={!editing}
                  />
                </label>
              </div>
            </div>

          </div>

          <div className="flex flex-wrap">
            {/* <div className="div-area-operator-row">
                  <div className="isAreaOperator"></div> */}

            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">


                <FormControl
                  label="Is this user an area operator?"
                // caption="RadioGroup caption"
                >
                  <RadioGroup
                    value={isAreaOperator}
                    onChange={event => setIsAreaOperator(event.currentTarget.value)}
                    align={ALIGN.horizontal}
                    readOnly={!editing}
                    disabled={!editing}
                  >
                    <Radio value="1">Yes</Radio>
                    <Radio value="0">No</Radio>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4" hidden={!(isAreaOperator === "1")}>
              <div className="relative w-full mb-3">
                {/* <div className="area-operator-area-selection" hidden={!(isAreaOperator === "1")}> */}
                <label htmlFor="field1"><span>Select the areas <span className="required">*</span></span>
                  <Select className="dropdown-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={[]}
                    isMulti
                    // options={areasOptions.filter(site => (arraySiteOperator === null || (arraySiteOperator !== null && !arraySiteOperator.includes([site.id_site]))))}
                    options={areasOptions.filter(site => (arraySiteOperator === null || (arraySiteOperator !== null && !check_if_site_of_the_area_is_selected(arraySiteOperator, site.id_site))))}
                    value={areaOperator}
                    // options={areasOptions}
                    formatGroupLabel={formatGroupLabel}
                    onChange={(selectedOption) => {
                      var temp = [];
                      if (selectedOption) {
                        selectedOption.forEach((selectedItem) => {
                          temp.push(selectedItem.value)
                        })
                      }
                      setArrayAreaOperator(temp);
                    }}
                    readOnly={!editing}
                    isDisabled={!editing}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>


        <div className="flex flex-wrap">
          {/* <div className="div-area-operator-row"> */}
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              {/* <div className="isLineOperator"> */}

              <FormControl
                label="Is this user a line operator?"
              >
                <RadioGroup
                  value={isLineOperator}
                  onChange={event => setIsLineOperator(event.currentTarget.value)}
                  align={ALIGN.horizontal}
                  readOnly={!editing}
                  disabled={!editing}
                >
                  <Radio value="1">Yes</Radio>
                  <Radio value="0">No</Radio>
                </RadioGroup>
              </FormControl>
            </div>
          </div>

          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <div className="line-operator-site-selection" hidden={!(isLineOperator === "1")}>
                <label htmlFor="field1"><span>Select the lines <span className="required">*</span></span>
                  <Select className="dropdown-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={[]}
                    isMulti
                    // options={areasOptions.filter(site => (arraySiteOperator !== null && !arraySiteOperator.includes([site.label])))}
                    options={linesOptions.filter(site => (arraySiteOperator === null || (arraySiteOperator !== null && !check_if_line_is_selected(arraySiteOperator, arrayAreaOperator, site.id_area, site.id_site))))}
                    value={lineOperator}
                    // options={linesOptions}
                    formatGroupLabel={formatGroupLabel}
                    onChange={(selectedOption) => {
                      var temp = [];
                      if (selectedOption) {
                        selectedOption.forEach((selectedItem) => {
                          temp.push(selectedItem.value)
                        })
                      }
                      setArrayLineOperator(temp);
                    }}
                    readOnly={!editing}
                    isDisabled={!editing}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  const viewerSection =
    <div className="flex flex-wrap">
      <div className="w-full lg:w-4/12 px-4">
        <div className="relative w-full mb-3">
          {/* <div className="isViewer">
      <div className="is-viewer-question"> */}
          <FormControl
            className="is-viewer-question-content"
            label="Is this user a viewer?"
          // caption="RadioGroup caption"
          >
            <RadioGroup
              value={isViewer}
              onChange={event => setIsViewer(event.currentTarget.value)}
              align={ALIGN.horizontal}
              readOnly={!editing}
              disabled={!editing}
            >
              <Radio value="1">Yes</Radio>
              <Radio value="0">No</Radio>
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      <div className="w-full lg:w-8/12 px-4" hidden={!(isViewer == true)}>
        <div className="relative w-full mb-3">
          <div className="flex flex-wrap">
            {/* <div className="viewer-categories" hidden={!(isViewer === "1")}> */}
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                {/* <div className="div-site-viewer-row">
          <div className="isSiteViewer"> */}
                <FormControl
                  label="Is this user a site viewer?"
                // caption="RadioGroup caption"
                >
                  <RadioGroup
                    value={isSiteViewer}
                    onChange={event => setIsSiteViewer(event.currentTarget.value)}
                    align={ALIGN.horizontal}
                    readOnly={!editing}
                    disabled={!editing}
                  >
                    <Radio value="1">Yes</Radio>
                    <Radio value="0">No</Radio>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4" hidden={!(isSiteViewer === "1")}>
              <div className="relative w-full mb-3">
                {/* <div className="site-viewer" hidden={!(isSiteViewer === "1")}> */}
                <label htmlFor="field1">
                  <span>Select the sites <span className="required">*</span></span>
                  <Select className="dropdown-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={[]}
                    isMulti
                    value={siteViewer}
                    options={sitesOptions.filter(site => (arraySiteManager === null || (arraySiteManager !== null && !check_if_site_is_selected(arraySiteManager, site.value))))}
                    // options={sitesOptions}
                    onChange={(selectedOption) => {
                      var temp = [];
                      if (selectedOption) {
                        selectedOption.forEach((selectedItem) => {
                          temp.push(selectedItem.value)
                        })
                      }
                      setArraySiteViewer(temp);
                    }}
                    readOnly={!editing}
                    isDisabled={!editing}
                  />
                </label>
              </div>
            </div>

          </div>

          <div className="flex flex-wrap">

            {/* <div className="div-area-viewer-row">
          <div className="isAreaViewer"> */}
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <FormControl
                  label="Is this user an area viewer?"
                // caption="RadioGroup caption"
                >
                  <RadioGroup
                    value={isAreaViewer}
                    onChange={event => setIsAreaViewer(event.currentTarget.value)}
                    align={ALIGN.horizontal}
                    readOnly={!editing}
                    disabled={!editing}
                  >
                    <Radio value="1">Yes</Radio>
                    <Radio value="0">No</Radio>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4" hidden={!(isAreaViewer === "1")}>
              <div className="relative w-full mb-3">
                {/* <div className="area-viewer-area-selection" hidden={!(isAreaViewer === "1")}> */}
                <label htmlFor="field1"><span>Select the areas <span className="required">*</span></span>
                  <Select className="dropdown-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={[]}
                    isMulti
                    // options={areasOptions.filter(site => (arraySiteViewer === null || (arraySiteViewer !== null && !arraySiteViewer.includes([site.id_site]))))}
                    options={areasOptions.filter(site => (arraySiteViewer === null || (arraySiteViewer !== null && !check_if_site_of_the_area_is_selected(arraySiteViewer, site.id_site))))}
                    value={areaViewer}
                    // options={areasOptions}
                    formatGroupLabel={formatGroupLabel}
                    onChange={(selectedOption) => {
                      var temp = [];
                      if (selectedOption) {
                        selectedOption.forEach((selectedItem) => {
                          temp.push(selectedItem.value)
                        })
                      }
                      setArrayAreaViewer(temp);
                    }}
                    readOnly={!editing}
                    isDisabled={!editing}
                  />
                </label>
              </div>
            </div>
          </div>


          <div className="flex flex-wrap">

            {/* <div className="div-area-viewer-row"> */}
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                {/* <div className="isLineViewer"> */}
                <FormControl
                  label="Is this user a line viewer?"
                >
                  <RadioGroup
                    value={isLineViewer}
                    onChange={event => setIsLineViewer(event.currentTarget.value)}
                    align={ALIGN.horizontal}
                    readOnly={!editing}
                    disabled={!editing}
                  >
                    <Radio value="1">Yes</Radio>
                    <Radio value="0">No</Radio>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">

                <div className="line-viewer-site-selection" hidden={!(isLineViewer === "1")}>
                  <label htmlFor="field1"><span>Select the lines <span className="required">*</span></span>
                    <Select className="dropdown-selection"
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      defaultValue={[]}
                      isMulti
                      // options={areasOptions.filter(site => (arraySiteViewer !== null && !arraySiteViewer.includes([site.label])))}
                      options={linesOptions.filter(site => (arraySiteViewer === null || (arraySiteViewer !== null && !check_if_line_is_selected(arraySiteViewer, arrayAreaViewer, site.id_area, site.id_site))))}
                      value={lineViewer}
                      // options={linesOptions}
                      formatGroupLabel={formatGroupLabel}
                      onChange={(selectedOption) => {
                        var temp = [];
                        if (selectedOption) {
                          selectedOption.forEach((selectedItem) => {
                            temp.push(selectedItem.value)
                          })
                        }
                        setArrayLineViewer(temp);
                      }}
                      readOnly={!editing}
                      isDisabled={!editing}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div>


  const overviewerSection =
    <div className="flex flex-wrap">
      <div className="w-full lg:w-4/12 px-4">
        <div className="relative w-full mb-3">

          {/* <div className="isOverviewer">
      <div className="is-overviewer-question"> */}
          <FormControl
            className="is-overviewer-question-content"
            label="Is this user an overviewer?"
          // caption="RadioGroup caption"
          >
            <RadioGroup
              value={isOverviewer}
              onChange={event => setIsOverviewer(event.currentTarget.value)}
              align={ALIGN.horizontal}
              readOnly={!editing}
              disabled={!editing}
            >
              <Radio value="1">Yes</Radio>
              <Radio value="0">No</Radio>
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className="w-full lg:w-4/12 px-4" hidden={!(isOverviewer === "1")}>
        <div className="relative w-full mb-3">
          {/* <div className="overviewer-categories" hidden={!(isOverviewer === "1")}> */}

          <label htmlFor="field1"><span>Select the lines <span className="required">*</span></span>
            <Select className="dropdown-selection"
              closeMenuOnSelect={true}
              components={animatedComponents}
              defaultValue={[]}
              isMulti
              // options={linesOptions.filter(site => (arraySiteOverviewer === null || (arraySiteOverviewer !== null && !check_if_line_is_selected(arraySiteOverviewer, arrayAreaOverviewer, site.id_area, site.id_site))))}
              value={lineOverviewer}
              options={linesOptions}
              formatGroupLabel={formatGroupLabel}
              onChange={(selectedOption) => {
                var temp = [];
                if (selectedOption) {
                  selectedOption.forEach((selectedItem) => {
                    temp.push(selectedItem.value)
                  })
                }
                setArrayLineOverviewViewer(temp);
              }}
              readOnly={!editing}
              isDisabled={!editing}
            />
          </label>

        </div>
      </div>
    </div>


  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-gray-800 text-xl font-bold">User details</h6>
            {editing ?
              <>
                <button
                  className="bg-white-500 text-black active:bg-white-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleCancelButton}
                >
                  Cancel
            </button>
                <button
                  className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleSaveButton}
                >
                  Save
            </button>
              </> :
              <>
                {
                  isSelectedUserDisabled ?
                    <>
                      <button
                        className="bg-green-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={handleEnableButton}
                        disabled={selectedUser === null}
                      >
                        Enable user
                  </button>
                    </> :
                    <>
                      <button
                        className="bg-red-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={handleDisableButton}
                        disabled={selectedUser === null}
                      >
                        Disable user
                      </button>
                    </>
                }
                <button
                  className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleEditButton}
                  disabled={selectedUser === null}
                >
                  Edit
          </button>
              </>}

          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
              User Information
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    autoComplete="off"
                    value={editUserName}
                    readOnly={!editing}
                    disabled={!editing}
                    onChange={e => setEditUserName(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    autoComplete="off"
                    value={editUserEmail}
                    readOnly={!editing}
                    disabled={!editing}
                    onChange={e => setEditUserEmail(e.target.value)} />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Password
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    autoComplete="off"
                    placeholder="Only fill if changing password..."
                    value={editUserPassword}
                    onChange={e => setEditUserPassword(e.target.value)}
                    readOnly={!editing}
                    disabled={!editing}
                  />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Phone
                  </label>
                  <PhoneInput
                    defaultCountry="DE"
                    value={editUserPhone}
                    onChange={setEditUserPhone}
                    readOnly={!editing}
                    disabled={!editing}
                    onChange={setEditUserPhone}
                  />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    User group
                  </label>
                  <Select
                    name="enterprise"
                    value={userGroupOptions.find(group => group.value === editUserUserGroups)}
                    options={userGroupOptions}
                    onChange={e => { setEditUserUserGroups(e.value); }}
                    readOnly={!editing}
                    isDisabled={!editing}
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-gray-400" />
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
              Manager section
            </h6>
            {managerSection}

            <hr className="mt-6 border-b-1 border-gray-400" />
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
              Operator section
            </h6>
            {operatorSection}

            <hr className="mt-6 border-b-1 border-gray-400" />
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
              Viewer section
            </h6>
            {viewerSection}

            <hr className="mt-6 border-b-1 border-gray-400" />
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
              Overviewer section
            </h6>
            {overviewerSection}
            <hr className="mt-6 border-b-1 border-gray-400" />
          </form>
        </div>
      </div>
    </>
  );
}
