import React from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import RegisterUser from "views/admin/RegisterUser.js";
import RegisterEnterprise from "views/admin/RegisterEnterprise.js";
import Test from "views/admin/Test.js";
import RegisterMultipleUsers from "views/admin/RegisterMultipleUsers.js";
import ListUsers from "views/admin/ListUsers.js";
import ListUsers40 from "views/admin/ListUsers40.js";
import RegisterLine from "views/admin/RegisterLine.js";


import Tables from "views/admin/Tables.js";

export default function Admin() {
  const history = useHistory();
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');

  if(token && refreshToken){

  return (
    <>
      <Sidebar/>
      <div className="relative md:ml-64 bg-gray-200">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/dashboard/:id_enterprise" exact component={Dashboard} />
            <Route path="/admin/register_user/:id_enterprise" exact component={RegisterUser} />
            <Route path="/admin/register_enterprise" exact component={RegisterEnterprise} />
            <Route path="/admin/register_multiple_users" exact component={RegisterMultipleUsers} />
            <Route path="/admin/users" exact component={ListUsers} />
            <Route path="/admin/40/users" exact component={ListUsers40} />
            <Route path="/admin/register_line/:id_enterprise" exact component={RegisterLine} />

            {/* <Route path="/admin/maps" exact component={Maps} /> */}
            {/* <Route path="/admin/tables" exact component={Tables} /> */}
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );}
  else{
    console.log("No token. Redirecting to login page")
    history.push('/auth/login');
    return null;
  }
}
