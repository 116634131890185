import React from "react";
import {useRouteMatch} from "react-router-dom"

// components

import CardNoEnterpriseDashboard from "components/Cards/CardNoEnterpriseDashboard";
import CardEnterpriseDashboard from "components/Cards/CardEnterpriseDashboard.js";

export default function Dashboard() {
  const id_enterprise = useRouteMatch().params.id_enterprise;

  if (id_enterprise) {
    return (
      <>
        <div className="flex flex-wrap">
          <div className="w-full xl:w-4/12 px-4">
            <CardEnterpriseDashboard id_enterprise={id_enterprise} />
          </div>
        </div>

      </>
    );
  }
  else {
    return (
      <>
        <div className="flex flex-wrap">
          <div className="w-full xl:w-4/12 px-4">
            <CardNoEnterpriseDashboard />
          </div>
        </div>
      </>
    );
  }
}
