import React from "react";

export default function CardEnterpriseDashboard(props) {
  const id_enterprise = props.id_enterprise;

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-2 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h1>In the future you should see here information about the following enterprise: {id_enterprise}.</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
