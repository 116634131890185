import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Chart } from "react-google-charts";


import api from '../../../services/api';
import './styles.css'

export default function CardListUsers(props) {
  const history = useHistory();
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');

  const [userList, setUserList] = useState([]);
  const [userListTable, setUserListTable] = useState(<></>);

  const [enterprise, setEnterprise] = useState(props.enterprise||0);

  //#region  Loads all the data
  useEffect(() => {
    api.get(
      `api/users/${enterprise}`, {
      headers: {
        token: token,
        refreshToken: refreshToken,
      }
    }).then(response => {
      setUserList(response.data);
      console.log(response.data)
    })
  }, [token]);

  //Creates the userList chart
  useEffect(() => {
    const colunasUserList = [
      { type: 'string', label: 'Name' },
      // { type: 'string', label: 'UID' },
      { type: 'string', label: 'Email' },
      // { type: 'boolean', label: 'Verified' },
      // { type: 'boolean', label: 'Enabled' },
      // { type: 'string', label: 'Tokens Valid Since' },
      { type: 'string', label: 'Phone' },
    ];

    var array = [];
    array.push(colunasUserList);

    for (var i in userList) {
      var element = [];
      element.push(userList[i].user_name);
      element.push(userList[i].user_email);
      // element.push(!userList[i].disabled);
      element.push(userList[i].phone_number);

      array.push(element);
    }

    setUserListTable(
        <Chart
          // style={{ width: 50 + "%", display: "table", margin: "0 auto" }}
          chartType="Table"
          loader={<div>Loading Chart</div>}
          data={array}
          options={{
            showRowNumber: false,
            page: 'enable',
            pageSize: 20,
            cssClassNames: {
              'headerRow': 'italic-darkblue-font large-font bold-font',
              // 'tableRow': 'orange-background',
              // 'oddTableRow': 'orange-background',
              'selectedTableRow': 'orange-background large-font',
              'hoverTableRow': '',
              'headerCell': 'gold-border',
              'tableCell': '',
              'rowNumberCell': 'underline-blue-font'
            }
          }}
          // formatters={[
            // {
            //   type: 'PatternFormat',
            //   column: [1],
            //   options: '<a href="mailto:{1}">{1}</a>',
            // },
            // {
            //   type: 'NumberFormat',
            //   column: 4,
            //   options: {
            //     // prefix: '$',
            //     // negativeColor: 'red',
            //     // negativeParens: true,
            //     groupingSymbol: '',
            //     fractionDigits: 0
            //   },
            // },
          // ]}
          width={"100%"}
          // height={"400px"}
          legendToggle
          chartEvents={[
            {
              eventName: "select",
              callback: ({ chartWrapper, google }) => {
                const chart = chartWrapper.getChart();
                const selected = chart.getSelection();
                chart.setSelection([]);
                const dataTable = chartWrapper.getDataTable();
                // console.log(chartWrapper.arguments)
                // console.log(chartWrapper.getChart())
                // console.log(chartWrapper);
                
                // var myView = new google.visualization.DataView(dataTable);
                // setDataView(myView);


                if (selected.length > 0) {
                  const [selectedItem] = selected;
                  const { row, column } = selectedItem
                  props.callback(userList.find(user => user.user_email === dataTable.getValue(row,1)))
                }


                // console.log(row);
                // console.log(dataTable.getValue(row, column))
              }
            }
          ]}
          controls={[
            {
              controlEvents: [
                {
                  eventName: 'statechange',
                  callback: ({ chartWrapper, controlWrapper }) => {
                    // alert(
                    //   'State changed to ' + JSON.stringify(controlWrapper.getState()),
                    // )
                  },
                },
              ],


              controlType: 'StringFilter',
              options: {
                //filterColumnIndex: 1,
                filterColumnLabel: "Email",
                matchType: "any",

                ui: {
                  //   labelStacking: 'horizontal',
                  label: 'Search by email',
                  labelSeparator: ':',
                  //   allowTyping: true,
                  //   allowMultiple: true,
                },
              },
            },
          ]
          }
        />
    );

  }, [userList]);
  //#endregion


  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-gray-800 text-xl font-bold">Users managment</h6>
            {/* <button
              className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Settings
            </button> */}
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
              List of users
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  {userListTable}
                </div>
              </div>
            </div>
            <hr className="mt-6 border-b-1 border-gray-400" />
            {/* <div className="flex flex-wrap">
              <div className="relative w-3/12 mb-8">
                <div className="form-buttons flex mb-2">
                  <button type="reset" name="cancelButton" className="cancelButton">Cancel</button>
                </div>
              </div>
              <div className="relative w-3/12 mb-8">
                <div className="form-buttons flex mb-8">
                  <button type="submit" onClick={handleRegisterUser} name="submitButton" className="submitButton">Save</button>
                </div>
              </div>
            </div> */}
          </form>
        </div>
      </div>
    </>
  );
}
