import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';

import LineFlow from '../../LineFlow'

import api from '../../../services/api';
import './styles.css'


export default function CardCreateLine() {

  const history = useHistory();
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  const chartSimple = {
    offset: {
      x: 0,
      y: 0
    },
    nodes: {
      node1: {
        id: "node1",
        type: "output-only",
        position: {
          x: 300,
          y: 100
        },
        ports: {
          port1: {
            id: "port1",
            type: "output",
            properties: {
              value: "yes"
            }
          },
          port2: {
            id: "port2",
            type: "output",
            properties: {
              value: "no"
            }
          }
        }
      },
      node2: {
        id: "node2",
        type: "input-output",
        position: {
          x: 300,
          y: 300
        },
        ports: {
          port1: {
            id: "port1",
            type: "input"
          },
          port2: {
            id: "port2",
            type: "output"
          }
        }
      },
      node3: {
        id: "node3",
        type: "input-output",
        position: {
          x: 100,
          y: 600
        },
        ports: {
          port1: {
            id: "port1",
            type: "input"
          },
          port2: {
            id: "port2",
            type: "output"
          }
        }
      },
      node4: {
        id: "node4",
        type: "input-output",
        position: {
          x: 500,
          y: 600
        },
        ports: {
          port1: {
            id: "port1",
            type: "input"
          },
          port2: {
            id: "port2",
            type: "output"
          }
        }
      }
    },
    links: {
      link1: {
        id: "link1",
        from: {
          nodeId: "node1",
          portId: "port2"
        },
        to: {
          nodeId: "node2",
          portId: "port1"
        },
        properties: {
          label: "example link label"
        }
      },
      link2: {
        id: "link2",
        from: {
          nodeId: "node2",
          portId: "port2"
        },
        to: {
          nodeId: "node3",
          portId: "port1"
        },
        properties: {
          label: "another example link label"
        }
      },
      link3: {
        id: "link3",
        from: {
          nodeId: "node2",
          portId: "port2"
        },
        to: {
          nodeId: "node4",
          portId: "port1"
        }
      }
    },
    selected: {},
    hovered: {}
  };

    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-gray-800 text-xl font-bold">Register a new line</h6>
            </div>
          </div>
          {LineFlow}
          <div className="App">
      {/* <FlowChartWithState initialValue={chartSimple} /> */}
    </div>
        </div>
      </>
    );
  }
