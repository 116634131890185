/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import { Link, useHistory, useRouteMatch } from "react-router-dom";

import api from '../../services/api';

import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
import UserDropdown from "components/Dropdowns/UserDropdown.js";

export default function Sidebar() {
  const history = useHistory();
  const [token, setToken] = useState(localStorage.getItem('token'));
  const refreshToken = localStorage.getItem('refreshToken');

  const [enterprisesOptions, setEnterprisesOptions] = useState([]);
  const [selectedEnterprise, setSelectedEnterprise] = useState(localStorage.getItem('enterprise') || 0);
  const [enterprisesDefaultValue, setEnterprisesDefaultValue] = useState();

  useEffect(() => {
    for (var i in enterprisesOptions) {
      if (enterprisesOptions[i].value == selectedEnterprise) {
        setEnterprisesDefaultValue(enterprisesOptions[i]);
      }
    }
  }, [enterprisesOptions, selectedEnterprise])



  //#region  Loads all the data
  useEffect(() => {
    api.get(
      'api/enterprises', {
      headers: {
        token: token,
        refreshToken: refreshToken,
      }
    }).then(response => {
      setEnterprisesOptions(response.data.map(enterprise => ({ value: enterprise.id_enterprise, label: enterprise.nm_enterprise })));
      // if(response.headers.token){
      //   localStorage.setItem('token', response.headers.token);
      //   setToken(response.headers.token)
      // }
    }).catch((error) => {
      console.log("erro")
      console.log(error.response)
      if (error.response.status === 401 || error.response.status === 400) {
        localStorage.clear();
        history.push('/');
      }
    })
  }, [token, refreshToken]);

  const [collapseShow, setCollapseShow] = React.useState("hidden");
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
            to="/admin"
            onClick={() => localStorage.setItem("enterprise", 0)}
          >
            PackIOT
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Notus React
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="px-3 py-2 h-12 border border-solid  border-gray-600 placeholder-gray-400 text-gray-700 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>
            <Select
              name="enterprise"
              placeholder="Select an enterprise"
              // defaultValue={enterprisesDefaultValue}
              value={enterprisesDefaultValue}
              options={enterprisesOptions}
              onChange={(e) => {
                console.log(e);
                setSelectedEnterprise(e.value);
                history.push(`/admin/dashboard/${e.value}`)
                localStorage.setItem("enterprise", e.value);
                // history.go(0);
                // window.location.reload();

              }}
            />


            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {(selectedEnterprise !== 0) ?
              <>
                {/* Heading */}
                <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                  Users management
                </h6>
                {/* Navigation */}

                <ul className="md:flex-col md:min-w-full flex flex-col list-none">

                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf(`/admin/register_user`) !== -1
                          ? "text-blue-500 hover:text-blue-600"
                          : "text-gray-800 hover:text-gray-600")
                      }
                      to={"/admin/register_user/" + selectedEnterprise}
                      title={(selectedEnterprise === 0) ? "Select an enterprise first." : ""}
                    >
                      <i
                        className={
                          "fas fa-tools mr-2 text-sm " +
                          (window.location.href.indexOf(`/admin/register_user`) !== -1
                            ? "opacity-75"
                            : "text-gray-400")
                        }
                      ></i>{" "}
                      Register User
                    </Link>
                  </li>

                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf(`/admin/register_multiple_users`) !== -1
                          ? "text-blue-500 hover:text-blue-600"
                          : "text-gray-800 hover:text-gray-600")
                      }
                      to={"/admin/register_multiple_users"}
                      title={(selectedEnterprise === 0) ? "Select an enterprise first." : ""}
                    >
                      <i
                        className={
                          "fas fa-tools mr-2 text-sm " +
                          (window.location.href.indexOf(`/admin/register_multiple_users`) !== -1
                            ? "opacity-75"
                            : "text-gray-400")
                        }
                      ></i>{" "}
                      Register Many Users
                    </Link>
                  </li>

                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/users") !== -1
                          ? "text-blue-500 hover:text-blue-600"
                          : "text-gray-800 hover:text-gray-600")
                      }
                      to="/admin/users"
                    >
                      <i
                        className={
                          "fas fa-table mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/users") !== -1
                            ? "opacity-75"
                            : "text-gray-400")
                        }
                      ></i>{" "}
                      Search user
                    </Link>
                  </li>

                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/40/users") !== -1
                          ? "text-blue-500 hover:text-blue-600"
                          : "text-gray-800 hover:text-gray-600")
                      }
                      to="/admin/40/users"
                    >
                      <i
                        className={
                          "fas fa-table mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/40/users") !== -1
                            ? "opacity-75"
                            : "text-gray-400")
                        }
                      ></i>{" "}
                      Search user PACK4
                    </Link>
                  </li>

                </ul>


                {/* Divider */}
                <hr className="my-4 md:min-w-full" />
                {/* Heading */}
                <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                  Sites management
                </h6>
                {/* Navigation */}

                <ul className="md:flex-col md:min-w-full flex flex-col list-none">

                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/register_site") !== -1
                          ? "text-blue-500 hover:text-blue-600"
                          : "text-gray-800 hover:text-gray-600")
                      }
                      to="/admin/register_site"
                    >
                      <i
                        className={
                          "fas fa-tools mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/register_site") !== -1
                            ? "opacity-75"
                            : "text-gray-400")
                        }
                      ></i>{" "}
                      Register Site
                    </Link>
                  </li>

                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/tables") !== -1
                          ? "text-blue-500 hover:text-blue-600"
                          : "text-gray-800 hover:text-gray-600")
                      }
                      to="/admin/tables"
                    >
                      <i
                        className={
                          "fas fa-table mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/tables") !== -1
                            ? "opacity-75"
                            : "text-gray-400")
                        }
                      ></i>{" "}
                      Search Site
                    </Link>
                  </li>

                </ul>
                {/* Divider */}
                <hr className="my-4 md:min-w-full" />

                <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                  Area management
                </h6>
                {/* Navigation */}

                <ul className="md:flex-col md:min-w-full flex flex-col list-none">

                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf(`/admin/register_area`) !== -1
                          ? "text-blue-500 hover:text-blue-600"
                          : "text-gray-800 hover:text-gray-600")
                      }
                      to={"/admin/register_area/" + selectedEnterprise}
                      title={(selectedEnterprise === 0) ? "Select an enterprise first." : ""}
                    >
                      <i
                        className={
                          "fas fa-tools mr-2 text-sm " +
                          (window.location.href.indexOf(`/admin/register_area`) !== -1
                            ? "opacity-75"
                            : "text-gray-400")
                        }
                      ></i>{" "}
                      Register Area
                    </Link>
                  </li>

                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/tables") !== -1
                          ? "text-blue-500 hover:text-blue-600"
                          : "text-gray-800 hover:text-gray-600")
                      }
                      to="/admin/tables"
                    >
                      <i
                        className={
                          "fas fa-table mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/tables") !== -1
                            ? "opacity-75"
                            : "text-gray-400")
                        }
                      ></i>{" "}
                      Search area
                    </Link>
                  </li>

                </ul>


                {/* Divider */}
                <hr className="my-4 md:min-w-full" />


                <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                  Line management
                </h6>
                {/* Navigation */}

                <ul className="md:flex-col md:min-w-full flex flex-col list-none">

                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf(`/admin/register_line`) !== -1
                          ? "text-blue-500 hover:text-blue-600"
                          : "text-gray-800 hover:text-gray-600")
                      }
                      to={"/admin/register_line/" + selectedEnterprise}
                      title={(selectedEnterprise === 0) ? "Select an enterprise first." : ""}
                    >
                      <i
                        className={
                          "fas fa-tools mr-2 text-sm " +
                          (window.location.href.indexOf(`/admin/register_line`) !== -1
                            ? "opacity-75"
                            : "text-gray-400")
                        }
                      ></i>{" "}
                      Register line
                    </Link>
                  </li>

                  <li className="items-center">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/tables") !== -1
                          ? "text-blue-500 hover:text-blue-600"
                          : "text-gray-800 hover:text-gray-600")
                      }
                      to="/admin/tables"
                    >
                      <i
                        className={
                          "fas fa-table mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/tables") !== -1
                            ? "opacity-75"
                            : "text-gray-400")
                        }
                      ></i>{" "}
                      Search line
                    </Link>
                  </li>

                </ul>


                {/* Divider */}
                <hr className="my-4 md:min-w-full" />

              </> : <></>}
          </div>
        </div>
      </nav>
    </>
  );
}
