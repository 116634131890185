import React, {useState} from "react";
import { useHistory } from "react-router-dom";

import api from '../../services/api';

import packiotLogo from '../../assets/img/packiot-dark.svg'

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();

  function refreshPage(){ 
    window.location.reload(); 
  }

  async function handleLogin(e) {
    e.preventDefault();//Por padrão quando se envia um formulário a página é recadastrada.  
    console.log("handleLogin")
    try {
        await api.post('/session', { email, password })
            .then(response => {
                if(response.status===200){
                    localStorage.setItem('refreshToken', response.data.refreshToken);
                    localStorage.setItem('token', response.data.token);
                    history.push('/admin/dashboard');
                }
            })
            .catch((error) => {
                if (error.response.status === 403||error.response.status === 400) {
                    alert(error.response.data.error)
                } else {
                    alert('Erro ao logar.');
                }
            });
    } catch (err) {
        alert("Erro ao logar.");
    }
}

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                <a href="/#" onClick={refreshPage}><img src={packiotLogo} alt="PackIOT - Data Matters" size={220} /></a>
                <h6 className="text-gray-600 text-sm font-bold">
                    Admin Panel
                  </h6>
                </div>
                
                <hr className="mt-6 border-b-1 border-gray-400" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      onChange={e => setEmail(e.target.value)}
                      />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      onChange={e => setPassword(e.target.value)}
                    />
                  </div>
                  {/* <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox text-gray-800 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-gray-700">
                        Remember me
                      </span>
                    </label>
                  </div> */}

                  <div className="text-center mt-6">
                    <button
                      className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      onClick={handleLogin}
                      type="button"
                    >
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
