import React from "react";
import { useRouteMatch } from "react-router-dom";

import CardRegisterUser from "components/Cards/CardRegisterUser";

export default function RegisterUser() {

  const selected_enterprise = useRouteMatch().params.id_enterprise;
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <CardRegisterUser id_enterprise={selected_enterprise}/>
        </div>
      </div>
    </>
  );
}
