import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Checkbox,
  LABEL_PLACEMENT
} from "baseui/checkbox";
import { TimePicker } from 'baseui/timepicker'


import { TimezonePicker } from "baseui/timezonepicker";
import { SIZE } from "baseui/input";


import { FormControl } from 'baseui/form-control';
import makeAnimated from 'react-select/animated';

import { RadioGroup, Radio, ALIGN } from 'baseui/radio';

import api from '../../../services/api';
import './styles.css'


export default function CardRegisterEnterprise() {
  let location = useLocation();

  const animatedComponents = makeAnimated();

  const history = useHistory();
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');

  const [newEnterpriseName, setNewEnterpriseName] = useState("");
  const [newEnterpriseTimezone, setNewEnterpriseTimezone] = useState("");
  const [newEnterpriseActive, setNewEnterpriseActive] = useState(true);
  const [newEnterpriseWeekBeginDay, setNewEnterpriseWeekBeginDay] = useState(-1);
  const [newEnterpriseWeekBeginTime, setNewEnterpriseWeekBeginTime] = useState();
  const [newEnterpriseLogo, setNewEnterpriseLogo] = useState("");
  const [newEnterpriseWorkingDays, setNewEnterpriseWorkingDays] = React.useState([]);

  async function handleRegisterEnterprise(e) {
    console.log(newEnterpriseWeekBeginTime);
    console.log(newEnterpriseTimezone);

    e.preventDefault();
    if (newEnterpriseName === "" || newEnterpriseTimezone === "" || newEnterpriseWorkingDays === [] || newEnterpriseWeekBeginDay === -1) {
      alert('Preencha todos os dados obrigatórios.');
    } else {
      console.log(newEnterpriseWeekBeginTime);

      const data = {
        'nm_enterprise': newEnterpriseName,
        'week_begin': 0,//////////////////////////////////////////////To do
        'timezone': newEnterpriseTimezone.id,
        'logo_url': newEnterpriseLogo,
        'active': newEnterpriseActive,
        'working_days': newEnterpriseWorkingDays
      }
      console.log(data);
      //   try {
      //       const response = await api.post('api/users', data, {
      //           headers: {
      //               token: token,
      //               refreshToken: refreshToken,
      //           }
      //       })
      //           .then(() => {
      //               alert(`Usuário cadastrado com sucesso`);
      //               history.push('/menu');
      //           })
      //           .catch((error) => {
      //               if (error.response.status === 401 || error.response.status === 400) {
      //                   localStorage.clear();
      //                   history.push('/');
      //               } else if (error.response.status === 403) {
      //                   alert(`User already exists.`)
      //               }
      //           })
      //   } catch (err) {
      //       alert('Erro no cadastro, tente novamente.');
      //   }
      // }
    }
  }
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-gray-800 text-xl font-bold">Register a new enterprise</h6>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
                Enterprise Information
            </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Name
                  </label>
                    <input
                      type="text"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      autoComplete="off"
                      value={newEnterpriseName}
                      onChange={e => setNewEnterpriseName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Timezone
                    </label>
                    {/* <input
                    type="email"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    autoComplete="off"
                    value={newEnterpriseTimezone}
                    onChange={e => setNewEnterpriseTimezone(e.target.value)}
                  /> */}
                    <TimezonePicker
                      value={newEnterpriseTimezone}
                      size={SIZE.compact}
                      onChange={(e) => {
                        console.log(e)
                      }}
                      date={new Date("2020-10-19T01:39:57.183Z")}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Logo URL
                  </label>
                    <input
                      type="text"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      autoComplete="off"
                      value={newEnterpriseLogo}
                      onChange={e => setNewEnterpriseLogo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">

                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Working days
                  </label>
                    <Select className="dropdown-selection"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      defaultValue={[]}
                      isMulti
                      options={[{ value: 0, label: "Monday" }, { value: 1, label: "Tuesday" }, { value: 2, label: "Wednesday" }, { value: 3, label: "Thursday" }, { value: 4, label: "Friday" }, { value: 5, label: "Saturday" }, { value: 6, label: "Sunday" }]}
                      onChange={(selectedOption) => {
                        setNewEnterpriseWorkingDays(selectedOption);
                      }}
                    />
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Activate enterprise
                    </label>
                    <Checkbox
                      checked={newEnterpriseActive}
                      onChange={e => setNewEnterpriseActive(e.target.checked)}
                      labelPlacement={LABEL_PLACEMENT.right}
                    />

                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Week begining
                  </label>
                    <Select className="dropdown-selection"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      defaultValue={[]}
                      isMulti
                      options={[{ value: 0, label: "Monday" }, { value: 1, label: "Tuesday" }, { value: 2, label: "Wednesday" }, { value: 3, label: "Thursday" }, { value: 4, label: "Friday" }, { value: 5, label: "Saturday" }, { value: 6, label: "Sunday" }]}
                      onChange={(selectedOption) => {
                        setNewEnterpriseWeekBeginDay(selectedOption);
                      }}
                    />

                  </div>
                </div>

                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Week begining
                  </label>
                    <TimePicker
                      value={newEnterpriseWeekBeginTime}
                      onChange={date => console.log(date)}
                      creatable
                      format="24"
                      step={900}
                    />

                  </div>
                </div>

              </div>

              <hr className="mt-6 border-b-1 border-gray-400" />


              <div className="flex flex-wrap">
                <div className="relative w-3/12 mb-8">
                  <div className="form-buttons flex mb-2">
                    <button type="reset" name="cancelButton" className="cancelButton">Cancel</button>
                  </div>
                </div>
                <div className="relative w-3/12 mb-8">
                  <div className="form-buttons flex mb-8">
                    <button type="submit" onClick={handleRegisterEnterprise} name="submitButton" className="submitButton">Save</button>
                  </div>
                </div>
              </div>


            </form>
          </div>
        </div>
      </>
    );
  }
