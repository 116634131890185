import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import api from '../../../services/api';
import './styles.css'


export default function CardUserDetails40(props) {
  const [selectedUser, setSelectedUser] = useState(props.selectedUser)
  const [enterprise, setEnterprise] = useState(localStorage.getItem('enterprise') || 0);
  const [isSelectedUserDisabled, setIsSelectedUserDisabled] = useState(-1)

  const history = useHistory();
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');

  const [editUserEmail, setEditUserEmail] = useState("");
  const [editUserName, setEditUserName] = useState("");
  const [editUserPassword, setEditUserPassword] = useState("");

  const [editing, setEditing] = useState(false);

  if (props.selectedUser !== selectedUser) {
    setSelectedUser(props.selectedUser)
  }

  function handleEditButton() {
    setEditing(true);
    props.isEditing(true);
  }
  async function handleSaveButton(e) {
    console.log('Save');
    // console.log(sitesOptions);
    e.preventDefault();
    
    if (editUserEmail === "" || editUserName === "" || enterprise === 0 ) {
      alert('Preencha todos os dados obrigatórios.');
    } else {
      const data = {
        'uid': selectedUser.id_user_firebase,
        'name': editUserName,
        'email': editUserEmail,
        'password': editUserPassword,
        'enterprise': enterprise,
        'disabled': false
      };

      console.log(data);

      try {
        await api.put('api/40/users', data, {
          headers: {
            token: token,
            refreshToken: refreshToken,
          }
        })
          .then((response) => {
            alert(`Usuário editado com sucesso`);
            history.push(`admin/dashboard/${enterprise}`);
            if (response.data.token) {
              localStorage.setItem('token', response.data.token);
            }
          })
          .catch((error) => {
            if (error.response.status === 401 || error.response.status === 400) {
              localStorage.clear();
              history.push('/');
            }
          })
      } catch (err) {
        alert('Erro no cadastro, tente novamente.');
      }
    }
  }
  function handleCancelButton() {
    setEditing(false);
  }

  if (props.selectedUser !== selectedUser) {
    setSelectedUser(props.selectedUser);
    setEditing(false);
  }

  async function handleEnableButton(e) {
    const data = [{
      'enterprise': enterprise,
      'uid': selectedUser.id_user_firebase,
    }];

    try {
      await api.put('api/40/users/enable', data, {
        headers: {
          token: token,
          refreshToken: refreshToken,
        }
      })
        .then((response) => {
          alert(`User enabled.`);
          setIsSelectedUserDisabled(0);
          // history.push(`admin/dashboard/${enterprise}`);
          if (response.data.token) {
            localStorage.setItem('token', response.data.token);
          }
        })
        .catch((error) => {
          if (error.response.status === 401 || error.response.status === 400) {
            localStorage.clear();
            history.push('/');
          } else if (error.response.status === 403) {
            alert(`Error enabling user.`)
          }
        })
    } catch (err) {
      alert('Error enabling user.');
    }
  }

  async function handleDisableButton(e) {
    const data = [{
      'enterprise': enterprise,
      'uid': selectedUser.id_user_firebase,
    }];

    try {
      await api.put('api/40/users/disable', data, {
        headers: {
          token: token,
          refreshToken: refreshToken,
        }
      })
        .then((response) => {
          console.log("oi")
          alert(`User disabled.`);
          // history.push(`admin/dashboard/${enterprise}`);
          setIsSelectedUserDisabled(1);
          if (response.data.token) {
            localStorage.setItem('token', response.data.token);
          }
        })
        .catch((error) => {
          if (error.response.status === 401 || error.response.status === 400) {
            localStorage.clear();
            history.push('/');
          } else if (error.response.status === 403) {
            alert(`Error disabling user.`)
          }
        })
    } catch (err) {
      alert('Error disabling user.');
    }
  }

  useEffect(() => {
    if (!editing) {
      if (selectedUser !== null) {
        console.log(selectedUser)
        setEditUserEmail(selectedUser.user_email);
        setEditUserName(selectedUser.user_name);
      }
    }
  }, [selectedUser, editing])


  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  //#region  Loads all the data



  useEffect(() => {
    async function checkIfEnabled() {
      try {
        await api.get(`api/40/user/enabled/${selectedUser.id_user_firebase}`, {
          headers: {
            token: token,
            refreshToken: refreshToken,
          }
        })
          .then((response) => {
            console.log(response.data)
            if (response.data.disabled == true) {
              setIsSelectedUserDisabled(1);
            } else {
              setIsSelectedUserDisabled(0);
            }
            if (response.data.token) {
              localStorage.setItem('token', response.data.token);
            }
          })
          .catch((error) => {
            if (error.response.status === 401 || error.response.status === 400) {
              localStorage.clear();
              history.push('/');
            }
          })
      } catch (err) {

      }
    }
    checkIfEnabled();
  }, [selectedUser])

  //#endregion


  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-gray-800 text-xl font-bold">User details</h6>
            {editing ?
              <>
                <button
                  className="bg-white-500 text-black active:bg-white-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleCancelButton}
                >
                  Cancel
                </button>
                <button
                  className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleSaveButton}
                >
                  Save
                </button>
              </> :
              <>
                {
                  isSelectedUserDisabled ?
                    <>
                      <button
                        className="bg-green-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={handleEnableButton}
                        disabled={selectedUser === null}
                      >
                        Enable user
                      </button>
                    </> :
                    <>
                      <button
                        className="bg-red-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={handleDisableButton}
                        disabled={selectedUser === null}
                      >
                        Disable user
                      </button>
                    </>
                }
                <button
                  className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleEditButton}
                  disabled={selectedUser === null}
                >
                  Edit
                </button>
              </>}

          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
              User Information
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    autoComplete="off"
                    value={editUserName}
                    readOnly={!editing}
                    disabled={!editing}
                    onChange={e => setEditUserName(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    autoComplete="off"
                    value={editUserEmail}
                    readOnly={!editing}
                    disabled={!editing}
                    onChange={e => setEditUserEmail(e.target.value)} />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Password
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    autoComplete="off"
                    placeholder="Only fill if changing password..."
                    value={editUserPassword}
                    onChange={e => setEditUserPassword(e.target.value)}
                    readOnly={!editing}
                    disabled={!editing}
                  />
                </div>
              </div>

              <hr className="mt-6 border-b-1 border-gray-400" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
