import React, { useState, useCallback } from "react";

import CardListUser40 from "components/Cards/CardListUsers40";
// import CardEditUser from "components/Cards/CardEditUser";
import CardEditUser2 from "components/Cards/CardEditUser2";
import CardUserDetails40 from "components/Cards/CardUserDetails40";


export default function ListUsers40() {
  const [enterprise, setEnterprise] = useState(localStorage.getItem("enterprise")||0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editing, setEditing] = useState(false);

  const changeSelectedUser = useCallback(user => {
    setSelectedUser(user);
  }, []);

  const isEditing = useCallback(value => {
    setEditing(value)
  }, []);

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-6/12 px-4">
          <CardListUser40 enterprise={enterprise}  callback={changeSelectedUser}/>
        </div>
        <div className="w-full lg:w-6/12 px-4">
          {/* {(editing&&selectedUser!==null) ? <CardEditUser2 isEditing={isEditing} enterprise={enterprise} selectedUser={selectedUser}/> : <CardUserDetails40 isEditing={isEditing} enterprise={enterprise} selectedUser={selectedUser}/>} */}
          <CardUserDetails40 isEditing={isEditing} enterprise={enterprise} selectedUser={selectedUser}/>
        </div>
      </div>
    </>
  );
}
