import axios from 'axios';
const apiURL = process.env.REACT_APP_ENV === 'dev' ? "http://localhost:3333" : "http://ec2-3-129-204-109.us-east-2.compute.amazonaws.com:3333";

const api = axios.create({
    baseURL: apiURL
})

api.interceptors.response.use(function (response) {
    if(response.headers.token){
        localStorage.setItem('token', response.headers.token);
    }
    return response;
  });

export default api;