import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useHistory, useLocation } from 'react-router-dom';

// import {
//   PhoneInput,
//   CountrySelectDropdown,
//   StyledFlag,
// } from 'baseui/phone-input';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { FormControl } from 'baseui/form-control';
import makeAnimated from 'react-select/animated';

import { RadioGroup, Radio, ALIGN } from 'baseui/radio';

import api from '../../../services/api';
import './styles.css'


export default function CardRegisterUser(props) {
  let location = useLocation();

  function check_if_site_is_selected(array_selected_sites, site) {
    for (var i in array_selected_sites) {
      if (array_selected_sites[i].value === site) {
        return true;
      }
    }
    return false;
  }

  function check_if_site_of_the_area_is_selected(array_selected_sites, site) {
    for (var i in array_selected_sites) {
      if (array_selected_sites[i].value === site) {
        return true;
      }
    }
    return false;
  }

  function check_if_line_is_selected(array_selected_sites, array_selected_areas, area, site) {
    for (var i in array_selected_sites) {
      if (array_selected_sites[i].value === site) {
        return true;
      }
    }
    for (var j in array_selected_areas) {
      if (array_selected_areas[j].value === area) {
        return true;
      }
    }
    return false;
  }

  const animatedComponents = makeAnimated();

  const newUserEnterprise = props.id_enterprise;

  const history = useHistory();
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');

  // const [newUserEnterprise, setNewUserEnterprise] = useState(0);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserName, setNewUserName] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [newUserUserGroups, setNewUserUserGroups] = useState([]);
  const [newUserLanguage, setNewUserLanguage] = useState({ value: "en", label: "English" });
  const [packVersion, setPackVersion] = useState({ value: "4", label: "Pack4" });
  // const [newUserPhoneCountry, setNewUserPhoneCountry] = React.useState({ label: "Brazil (Brasil)", id: "BR", dialCode: "+55" });
  const [newUserPhone, setNewUserPhone] = React.useState();

  const [arraySiteManager, setArraySiteManager] = React.useState([]);
  const [arrayAreaManager, setArrayAreaManager] = React.useState([]);
  const [arrayLineManager, setArrayLineManager] = React.useState([]);

  const [arraySiteOperator, setArraySiteOperator] = React.useState([]);
  const [arrayAreaOperator, setArrayAreaOperator] = React.useState([]);
  const [arrayLineOperator, setArrayLineOperator] = React.useState([]);

  const [arraySiteViewer, setArraySiteViewer] = React.useState([]);
  const [arrayAreaViewer, setArrayAreaViewer] = React.useState([]);
  const [arrayLineViewer, setArrayLineViewer] = React.useState([]);

  const [arraySiteOverviewer, setArraySiteOverviewer] = React.useState([]);
  const [arrayAreaOverviewer, setArrayAreaOverviewer] = React.useState([]);
  const [arrayLineOverviewer, setArrayLineOverviewer] = React.useState([]);

  const [isManager, setIsManager] = useState("0");
  const [isSiteManager, setIsSiteManager] = useState("0");
  const [isAreaManager, setIsAreaManager] = useState("0");
  const [isLineManager, setIsLineManager] = useState("0");

  const [isOperator, setIsOperator] = useState("0");
  const [isSiteOperator, setIsSiteOperator] = useState("0");
  const [isAreaOperator, setIsAreaOperator] = useState("0");
  const [isLineOperator, setIsLineOperator] = useState("0");

  const [isViewer, setIsViewer] = useState("0");
  const [isSiteViewer, setIsSiteViewer] = useState("0");
  const [isAreaViewer, setIsAreaViewer] = useState("0");
  const [isLineViewer, setIsLineViewer] = useState("0");

  const [isOverviewer, setIsOverviewer] = useState("0");
  const [isSiteOverviewer, setIsSiteOverviewer] = useState("0");
  const [isAreaOverviewer, setIsAreaOverviewer] = useState("0");
  const [isLineOverviewer, setIsLineOverviewer] = useState("0");

  const [sitesOptions, setSitesOptions] = useState([]);
  const [areasOptions, setAreasOptions] = useState([]);
  const [linesOptions, setLinesOptions] = useState([]);
  const [userGroupOptions, setUserGroupOptions] = useState([]);
  const [allEnterpriseData, setAllEnterpriseData] = useState({});

  async function handleRegisterUser(e) {
    // console.log(sitesOptions);
    e.preventDefault();
    var phone = "";
    if (newUserPhone !== undefined) {
      phone = newUserPhone;
    }

    function convertJsonArray_to_ValueArray(JsonArray) {
      if (JsonArray !== null) {
        return JsonArray.map(element => element.value);
      }
      return [];
    }
    if (newUserEmail === "" || newUserName === "" || newUserPassword === "" || newUserEnterprise === 0) {
      alert('Preencha todos os dados obrigatórios.');
    } else {
      const data = {
        'name': newUserName,
        'email': newUserEmail,
        'password': newUserPassword,
        'phone': phone,
        'enterprise': newUserEnterprise,
        'groups': [newUserUserGroups],
        'site_manager': convertJsonArray_to_ValueArray(arraySiteManager),
        'area_manager': convertJsonArray_to_ValueArray(arrayAreaManager),
        'line_manager': convertJsonArray_to_ValueArray(arrayLineManager),
        'site_operator': convertJsonArray_to_ValueArray(arraySiteOperator),
        'area_operator': convertJsonArray_to_ValueArray(arrayAreaOperator),
        'line_operator': convertJsonArray_to_ValueArray(arrayLineOperator),
        'site_viewer': convertJsonArray_to_ValueArray(arraySiteViewer),
        'area_viewer': convertJsonArray_to_ValueArray(arrayAreaViewer),
        'line_viewer': convertJsonArray_to_ValueArray(arrayLineViewer),
        'overviewer': convertJsonArray_to_ValueArray(arrayLineOverviewer),
        'language': newUserLanguage.value,
      };

      try {
        if (packVersion?.value=="1"){
          await api.post('api/users', data, {
            headers: {
              token: token,
              refreshToken: refreshToken,
            }
          })
            .then((response) => {
              alert(`Usuário cadastrado com sucesso`);
              history.push(`admin/dashboard/${newUserEnterprise}`);
              if (response.data.token) {
                localStorage.setItem('token', response.data.token);
              }
            })
            .catch((error) => {
              if (error.response.status === 401 || error.response.status === 400) {
                localStorage.clear();
                history.push('/');
              } else if (error.response.status === 403) {
                alert(`User already exists.`)
              }
            })
        }else{
          await api.post('api/packiot40/users', data, {
            headers: {
              token: token,
              refreshToken: refreshToken,
            }
          })
            .then((response) => {
              alert(`Usuário cadastrado com sucesso`);
              history.push(`admin/dashboard/${newUserEnterprise}`);
              if (response.data.token) {
                localStorage.setItem('token', response.data.token);
              }
            })
            .catch((error) => {
              if (error.response.status === 401 || error.response.status === 400) {
                localStorage.clear();
                history.push('/');
              } else if (error.response.status === 403) {
                alert(`User already exists.`)
              }
            })
        }
        
      } catch (err) {
        alert('Erro no cadastro, tente novamente.');
      }
    }
  }

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );



  //#region  Loads all the data

  useEffect(() => {
    api.get(
      `api/enterprises/group/${newUserEnterprise}`, {
      headers: {
        token: token,
        refreshToken: refreshToken,
      }
    }).then(response => {
      setUserGroupOptions(response.data.map(userGroup => ({ value: userGroup.id_user_group, label: userGroup.cd_user_group })));
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
      }
    }).catch((error) => {
      console.log("erro")
      console.log(error.response)
      if (error.response.status === 401 || error.response.status === 400) {
        localStorage.clear();
        history.push('/');
      }
    })
  }, [location, history, newUserEnterprise, refreshToken, token]);

  useEffect(() => {
    api.get(
      `api/enterprises/all/${newUserEnterprise}`, {
      headers: {
        token: token,
        refreshToken: refreshToken,
      }
    }).then(response => {
      setAllEnterpriseData(response);
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
      }
    }).catch((error) => {
      console.log("erro")
      console.log(error.response)
      if (error.response.status === 401 || error.response.status === 400) {
        localStorage.clear();
        history.push('/');
      }
    })
  }, [location, history, newUserEnterprise, refreshToken, token]);

  useEffect(() => {
    if (allEnterpriseData.data !== undefined) {
      setSitesOptions(allEnterpriseData.data.map(site => ({ value: parseInt(site.id_site), label: site.nm_site })));
    }
  }, [allEnterpriseData, location]);

  useEffect(() => {
    if (allEnterpriseData.data !== undefined) {
      // Sets the area options to the following format:
      //     [{
      //         label:'Site 1',
      //         options:[
      //             {value:1, label:"A"},
      //             {value:2, label:"B"},
      //             {value:3, label:"C"}]},
      //         {label:'Site 2',
      //             options:[
      //                 {value:4, label:"D"},
      //                 {value:5, label:"E"},
      //                 {value:6, label:"F"}
      //             ]
      //         }
      //     ]
      setAreasOptions(allEnterpriseData.data.map(site => ({ label: site.nm_site, id_site: parseInt(site.id_site), options: site.areas.map(area => ({ value: parseInt(area.id_area), label: `${site.nm_site}/${area.nm_area}` })) })));
      // console.log(allEnterpriseData.data.map(site => ({ label: site.nm_site, id_site: site.id_site, options: site.areas.map(area => ({ value: area.id_area, label: area.nm_area })) })));
    }
  }, [allEnterpriseData, location]);

  useEffect(() => {
    if (allEnterpriseData.data !== undefined) {
      var temp = allEnterpriseData.data.map(site => (site.areas.map(area => ({ label: area.nm_area, id_site: parseInt(site.id_site), id_area: parseInt(area.id_area), options: area.lines.map(line => ({ value: parseInt(line.id_equipment), label: `${site.nm_site}/${area.nm_area}/${line.nm_equipment}` })) }))));
      var i;
      var test_list = [];
      for (i of temp) {
        test_list = test_list.concat(i)
      }
      setLinesOptions(test_list);
      console.log(test_list);
    }
  }, [allEnterpriseData, location]);

  //Updates array when any answer is changed to "0"
  useEffect(() => {
    if (!isManager) {
      setArraySiteManager([]);
      setArrayAreaManager([]);
      setArrayLineManager([]);
    }

    if (!isOperator) {
      setArraySiteOperator([]);
      setArrayAreaOperator([]);
      setArrayLineOperator([]);
    }

    if (!isViewer) {
      setArraySiteViewer([]);
      setArrayAreaViewer([]);
      setArrayLineViewer([]);
    }

    if (!isSiteManager) {
      setArraySiteManager([]);
    }
    if (!isAreaManager) {
      setArrayAreaManager([]);
    }
    if (!isLineManager) {
      setArrayLineManager([]);
    }

    if (!isSiteOperator) {
      setArraySiteOperator([]);
    }
    if (!isAreaOperator) {
      setArrayAreaOperator([]);
    }
    if (!isLineOperator) {
      setArrayLineOperator([]);
    }

    if (!isSiteViewer) {
      setArraySiteViewer([]);
    }
    if (!isAreaViewer) {
      setArrayAreaViewer([]);
    }
    if (!isLineViewer) {
      setArrayLineViewer([]);
    }

    if (!isOverviewer) {
      setArrayLineOverviewer([]);
    }
  }, [isManager, isOperator, isViewer, isOverviewer, isSiteManager, isAreaManager, isLineManager, isSiteOperator, isAreaOperator, isLineOperator, isSiteViewer, isAreaViewer, isLineViewer, token, refreshToken, location])


  //#endregion

  const managerSection =
    <div className="flex flex-wrap">
      <div className="w-full lg:w-4/12 px-4">
        <div className="relative w-full mb-3">
          {/* <div className="isManager"> */}
          {/* <div className="is-manager-question"> */}
          <FormControl
            className="is-manager-question-content"
            label="Is this user a manager?"
            row={true}

          // caption="RadioGroup caption"
          >
            <RadioGroup
              value={isManager}
              onChange={event => setIsManager(event.currentTarget.value)}
              align={ALIGN.horizontal}
            >
              <Radio value="1">Yes</Radio>
              <Radio value="0">No</Radio>
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      <div className="w-full lg:w-8/12 px-4" hidden={!(isManager == true)}>
        <div className="relative w-full mb-3">
          <div className="flex flex-wrap">

            {/* <div className="manager-categories" hidden={!(isManager == true)}> */}
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">

                {/* <div className="div-site-manager-row"> */}
                {/* <div className="isSiteManager"> */}
                <FormControl
                  label="Is this user a site manager?"
                // caption="RadioGroup caption"
                >
                  <RadioGroup
                    value={isSiteManager}
                    onChange={event => setIsSiteManager(event.currentTarget.value)}
                    align={ALIGN.horizontal}
                  >
                    <Radio value="1">Yes</Radio>
                    <Radio value="0">No</Radio>
                  </RadioGroup>
                </FormControl>
                {/* </div> */}
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4" hidden={!(isSiteManager === "1")}>
              <div className="relative w-full mb-3">
                {/* <div className="site-manager" hidden={!(isSiteManager === "1")}> */}
                <label htmlFor="field1">
                  <span>Select the sites <span className="required">*</span></span>
                  <Select className="dropdown-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={[]}
                    isMulti
                    options={sitesOptions}
                    onChange={(selectedOption) => {
                      setArraySiteManager(selectedOption);
                      console.log(selectedOption)
                    }}
                  />
                </label>
              </div>
            </div>

          </div>

          <div className="flex flex-wrap">

            {/* <div className="div-area-manager-row">
              <div className="isAreaManager"> */}
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <FormControl
                  label="Is this user an area manager?"
                // caption="RadioGroup caption"
                >
                  <RadioGroup
                    value={isAreaManager}
                    onChange={event => setIsAreaManager(event.currentTarget.value)}
                    align={ALIGN.horizontal}
                  >
                    <Radio value="1">Yes</Radio>
                    <Radio value="0">No</Radio>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4" hidden={!(isAreaManager === "1")}>
              <div className="relative w-full mb-3">
                {/* <div className="area-manager-area-selection" hidden={!(isAreaManager === "1")}> */}
                <label htmlFor="field1"><span>Select the areas <span className="required">*</span></span>
                  <Select className="dropdown-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={[]}
                    isMulti
                    // options={areasOptions.filter(site => (arraySiteManager === null || (arraySiteManager !== null && !arraySiteManager.includes([site.id_site]))))}
                    options={areasOptions.filter(site => (arraySiteManager === null || (arraySiteManager !== null && !check_if_site_of_the_area_is_selected(arraySiteManager, site.id_site))))}
                    // options={areasOptions}
                    formatGroupLabel={formatGroupLabel}
                    onChange={(selectedOption) => {
                      setArrayAreaManager(selectedOption);
                    }}
                  />
                </label>
                {/* </div> */}
              </div>
            </div>
          </div>


          <div className="flex flex-wrap">
            {/* <div className="div-area-manager-row"> */}
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                {/* <div className="isLineManager"> */}
                <FormControl
                  label="Is this user a line manager?"
                >
                  <RadioGroup
                    value={isLineManager}
                    onChange={event => setIsLineManager(event.currentTarget.value)}
                    align={ALIGN.horizontal}
                  >
                    <Radio value="1">Yes</Radio>
                    <Radio value="0">No</Radio>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <div className="line-manager-site-selection" hidden={!(isLineManager === "1")}>
                  <label htmlFor="field1"><span>Select the lines <span className="required">*</span></span>
                    <Select className="dropdown-selection"
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      defaultValue={[]}
                      isMulti
                      // options={linesOptions.filter(site => (arraySiteManager === null || (arraySiteManager !== null && !check_if_line_is_selected(arraySiteManager, arrayAreaManager, site.id_area, site.id_site))))}

                      formatGroupLabel={formatGroupLabel}
                      onChange={(selectedOption) => {
                        setArrayLineManager(selectedOption);
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div>

  const operatorSection =
    <div className="flex flex-wrap">
      <div className="w-full lg:w-4/12 px-4">
        <div className="relative w-full mb-3">
          {/* <div className="isOperator">
      <div className="is-operator-question"> */}
          <FormControl
            className="is-operator-question-content"
            label="Is this user an operator?"
          // caption="RadioGroup caption"
          >
            <RadioGroup
              value={isOperator}
              onChange={event => setIsOperator(event.currentTarget.value)}
              align={ALIGN.horizontal}
            >
              <Radio value="1">Yes</Radio>
              <Radio value="0">No</Radio>
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      <div className="w-full lg:w-8/12 px-4" hidden={!(isOperator == true)}>
        <div className="relative w-full mb-3">
          <div className="flex flex-wrap">

            {/* <div className="operator-categories" hidden={!(isOperator === "1")}> */}
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">



                {/* <div className="div-site-operator-row">
                  <div className="isSiteOperator"> */}
                <FormControl
                  label="Is this user a site operator?"
                // caption="RadioGroup caption"
                >
                  <RadioGroup
                    value={isSiteOperator}
                    onChange={event => setIsSiteOperator(event.currentTarget.value)}
                    align={ALIGN.horizontal}
                  >
                    <Radio value="1">Yes</Radio>
                    <Radio value="0">No</Radio>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="w-full lg:w-6/12 px-4" hidden={!(isSiteOperator === "1")}>
              <div className="relative w-full mb-3">
                {/* <div className="site-operator" hidden={!(isSiteOperator === "1")}> */}

                <label htmlFor="field1">
                  <span>Select the sites <span className="required">*</span></span>
                  <Select className="dropdown-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={[]}
                    isMulti
                    options={sitesOptions}
                    onChange={(selectedOption) => {
                      setArraySiteOperator(selectedOption);
                    }}
                  />
                </label>
              </div>
            </div>

          </div>

          <div className="flex flex-wrap">
            {/* <div className="div-area-operator-row">
                  <div className="isAreaOperator"></div> */}

            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">


                <FormControl
                  label="Is this user an area operator?"
                // caption="RadioGroup caption"
                >
                  <RadioGroup
                    value={isAreaOperator}
                    onChange={event => setIsAreaOperator(event.currentTarget.value)}
                    align={ALIGN.horizontal}
                  >
                    <Radio value="1">Yes</Radio>
                    <Radio value="0">No</Radio>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4" hidden={!(isAreaOperator === "1")}>
              <div className="relative w-full mb-3">
                {/* <div className="area-operator-area-selection" hidden={!(isAreaOperator === "1")}> */}
                <label htmlFor="field1"><span>Select the areas <span className="required">*</span></span>
                  <Select className="dropdown-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={[]}
                    isMulti
                    // options={areasOptions.filter(site => (arraySiteOperator === null || (arraySiteOperator !== null && !arraySiteOperator.includes([site.id_site]))))}
                    options={areasOptions.filter(site => (arraySiteOperator === null || (arraySiteOperator !== null && !check_if_site_of_the_area_is_selected(arraySiteOperator, site.id_site))))}
                    // options={areasOptions}
                    formatGroupLabel={formatGroupLabel}
                    onChange={(selectedOption) => {
                      setArrayAreaOperator(selectedOption);
                    }}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>


        <div className="flex flex-wrap">
          {/* <div className="div-area-operator-row"> */}
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              {/* <div className="isLineOperator"> */}

              <FormControl
                label="Is this user a line operator?"
              >
                <RadioGroup
                  value={isLineOperator}
                  onChange={event => setIsLineOperator(event.currentTarget.value)}
                  align={ALIGN.horizontal}
                >
                  <Radio value="1">Yes</Radio>
                  <Radio value="0">No</Radio>
                </RadioGroup>
              </FormControl>
            </div>
          </div>

          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <div className="line-operator-site-selection" hidden={!(isLineOperator === "1")}>
                <label htmlFor="field1"><span>Select the lines <span className="required">*</span></span>
                  <Select className="dropdown-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={[]}
                    isMulti
                    // options={areasOptions.filter(site => (arraySiteOperator !== null && !arraySiteOperator.includes([site.label])))}
                    options={linesOptions.filter(site => (arraySiteOperator === null || (arraySiteOperator !== null && !check_if_line_is_selected(arraySiteOperator, arrayAreaOperator, site.id_area, site.id_site))))}
                    // options={linesOptions}
                    formatGroupLabel={formatGroupLabel}
                    onChange={(selectedOption) => {
                      setArrayLineOperator(selectedOption);
                    }}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  const viewerSection =
    <div className="flex flex-wrap">
      <div className="w-full lg:w-4/12 px-4">
        <div className="relative w-full mb-3">
          {/* <div className="isViewer">
      <div className="is-viewer-question"> */}
          <FormControl
            className="is-viewer-question-content"
            label="Is this user a viewer?"
          // caption="RadioGroup caption"
          >
            <RadioGroup
              value={isViewer}
              onChange={event => setIsViewer(event.currentTarget.value)}
              align={ALIGN.horizontal}
            >
              <Radio value="1">Yes</Radio>
              <Radio value="0">No</Radio>
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      <div className="w-full lg:w-8/12 px-4" hidden={!(isViewer == true)}>
        <div className="relative w-full mb-3">
          <div className="flex flex-wrap">
            {/* <div className="viewer-categories" hidden={!(isViewer === "1")}> */}
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                {/* <div className="div-site-viewer-row">
          <div className="isSiteViewer"> */}
                <FormControl
                  label="Is this user a site viewer?"
                // caption="RadioGroup caption"
                >
                  <RadioGroup
                    value={isSiteViewer}
                    onChange={event => setIsSiteViewer(event.currentTarget.value)}
                    align={ALIGN.horizontal}
                  >
                    <Radio value="1">Yes</Radio>
                    <Radio value="0">No</Radio>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4" hidden={!(isSiteViewer === "1")}>
              <div className="relative w-full mb-3">
                {/* <div className="site-viewer" hidden={!(isSiteViewer === "1")}> */}
                <label htmlFor="field1">
                  <span>Select the sites <span className="required">*</span></span>
                  <Select className="dropdown-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={[]}
                    isMulti
                    options={sitesOptions.filter(site => (arraySiteManager === null || (arraySiteManager !== null && !check_if_site_is_selected(arraySiteManager, site.value))))}
                    // options={sitesOptions}
                    onChange={(selectedOption) => {
                      setArraySiteViewer(selectedOption);
                      console.log(selectedOption)
                    }}
                  />
                </label>
              </div>
            </div>

          </div>

          <div className="flex flex-wrap">

            {/* <div className="div-area-viewer-row">
          <div className="isAreaViewer"> */}
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <FormControl
                  label="Is this user an area viewer?"
                // caption="RadioGroup caption"
                >
                  <RadioGroup
                    value={isAreaViewer}
                    onChange={event => setIsAreaViewer(event.currentTarget.value)}
                    align={ALIGN.horizontal}
                  >
                    <Radio value="1">Yes</Radio>
                    <Radio value="0">No</Radio>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4" hidden={!(isAreaViewer === "1")}>
              <div className="relative w-full mb-3">
                {/* <div className="area-viewer-area-selection" hidden={!(isAreaViewer === "1")}> */}
                <label htmlFor="field1"><span>Select the areas <span className="required">*</span></span>
                  <Select className="dropdown-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={[]}
                    isMulti
                    // options={areasOptions.filter(site => (arraySiteViewer === null || (arraySiteViewer !== null && !arraySiteViewer.includes([site.id_site]))))}
                    options={areasOptions.filter(site => (arraySiteViewer === null || (arraySiteViewer !== null && !check_if_site_of_the_area_is_selected(arraySiteViewer, site.id_site))))}
                    // options={areasOptions}
                    formatGroupLabel={formatGroupLabel}
                    onChange={(selectedOption) => {
                      setArrayAreaViewer(selectedOption);
                    }}
                  />
                </label>
              </div>
            </div>
          </div>


          <div className="flex flex-wrap">

            {/* <div className="div-area-viewer-row"> */}
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                {/* <div className="isLineViewer"> */}
                <FormControl
                  label="Is this user a line viewer?"
                >
                  <RadioGroup
                    value={isLineViewer}
                    onChange={event => setIsLineViewer(event.currentTarget.value)}
                    align={ALIGN.horizontal}
                  >
                    <Radio value="1">Yes</Radio>
                    <Radio value="0">No</Radio>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">

                <div className="line-viewer-site-selection" hidden={!(isLineViewer === "1")}>
                  <label htmlFor="field1"><span>Select the lines <span className="required">*</span></span>
                    <Select className="dropdown-selection"
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      defaultValue={[]}
                      isMulti
                      // options={areasOptions.filter(site => (arraySiteViewer !== null && !arraySiteViewer.includes([site.label])))}
                      options={linesOptions.filter(site => (arraySiteViewer === null || (arraySiteViewer !== null && !check_if_line_is_selected(arraySiteViewer, arrayAreaViewer, site.id_area, site.id_site))))}
                      // options={linesOptions}
                      formatGroupLabel={formatGroupLabel}
                      onChange={(selectedOption) => {
                        setArrayLineViewer(selectedOption);
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div>


  const overviewerSection =
    <div className="flex flex-wrap">
      <div className="w-full lg:w-4/12 px-4">
        <div className="relative w-full mb-3">

          {/* <div className="isOverviewer">
      <div className="is-overviewer-question"> */}
          <FormControl
            className="is-overviewer-question-content"
            label="Is this user an overviewer?"
            // caption="RadioGroup caption"
            row={true}
          >
            <RadioGroup
              value={isOverviewer}
              onChange={event => setIsOverviewer(event.currentTarget.value)}
              align={ALIGN.horizontal}
            >
              <Radio value="1">Yes</Radio>
              <Radio value="0">No</Radio>
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className="w-full lg:w-4/12 px-4" hidden={!(isOverviewer === "1")}>
        <div className="relative w-full mb-3">
          <div className="flex flex-wrap">
            {/* <div className="overviewer-categories" hidden={!(isOverviewer === "1")}> */}
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">

                {/* <div className="div-site-overviewer-row"> */}
                {/* <div className="isSiteOverviewer"> */}
                <FormControl
                  label="Is this user a site overviewer?"
                // caption="RadioGroup caption"
                >
                  <RadioGroup
                    value={isSiteOverviewer}
                    onChange={event => setIsSiteOverviewer(event.currentTarget.value)}
                    align={ALIGN.horizontal}
                  >
                    <Radio value="1">Yes</Radio>
                    <Radio value="0">No</Radio>
                  </RadioGroup>
                </FormControl>
                {/* </div> */}
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4" hidden={!(isSiteOverviewer === "1")}>
              <div className="relative w-full mb-3">
                {/* <div className="site-overviewer" hidden={!(isSiteOverviewer === "1")}> */}
                <label htmlFor="field1">
                  <span>Select the sites <span className="required">*</span></span>
                  <Select className="dropdown-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={[]}
                    isMulti
                    options={sitesOptions}
                    onChange={(selectedOption) => {
                      setArraySiteOverviewer(selectedOption);
                      console.log(selectedOption)
                    }}
                  />
                </label>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap">

            {/* <div className="div-area-overviewer-row">
          <div className="isAreaOverviewer"> */}
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <FormControl
                  label="Is this user an area overviewer?"
                // caption="RadioGroup caption"
                >
                  <RadioGroup
                    value={isAreaOverviewer}
                    onChange={event => setIsAreaOverviewer(event.currentTarget.value)}
                    align={ALIGN.horizontal}
                  >
                    <Radio value="1">Yes</Radio>
                    <Radio value="0">No</Radio>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4" hidden={!(isAreaOverviewer === "1")}>
              <div className="relative w-full mb-3">
                {/* <div className="area-rverviewer-area-selection" hidden={!(isAreaManager === "1")}> */}
                <label htmlFor="field1"><span>Select the areas <span className="required">*</span></span>
                  <Select className="dropdown-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={[]}
                    isMulti
                    // options={areasOptions.filter(site => (arraySiteOverviewer === null || (arraySiteManager !== null && !arraySiteManager.includes([site.id_site]))))}
                    options={areasOptions.filter(site => (arraySiteOverviewer === null || (arraySiteOverviewer !== null && !check_if_site_of_the_area_is_selected(arraySiteOverviewer, site.id_site))))}
                    // options={areasOptions}
                    formatGroupLabel={formatGroupLabel}
                    onChange={(selectedOption) => {
                      setArrayAreaOverviewer(selectedOption);
                    }}
                  />
                </label>
                {/* </div> */}
              </div>
            </div>
          </div>

          <div className="flex flex-wrap">
            {/* <div className="div-area-overviewer-row"> */}
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                {/* <div className="isLineOverviewer"> */}
                <FormControl
                  label="Is this user a line overviewer?"
                >
                  <RadioGroup
                    value={isLineOverviewer}
                    onChange={event => setIsLineOverviewer(event.currentTarget.value)}
                    align={ALIGN.horizontal}
                  >
                    <Radio value="1">Yes</Radio>
                    <Radio value="0">No</Radio>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <div className="line-overviewer-site-selection" hidden={!(isLineOverviewer === "1")}>
                  <label htmlFor="field1"><span>Select the lines <span className="required">*</span></span>
                    <Select className="dropdown-selection"
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      defaultValue={[]}
                      isMulti
                      // options={linesOptions.filter(site => (arraySiteOverviewer === null || (arraySiteOverviewer !== null && !check_if_line_is_selected(arraySiteOverviewer, arrayAreaOverviewer, site.id_area, site.id_site))))}

                      formatGroupLabel={formatGroupLabel}
                      onChange={(selectedOption) => {
                        setArrayLineOverviewer(selectedOption);
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>


  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-gray-800 text-xl font-bold">Register new user</h6>
            {/* <button
              className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Settings
            </button> */}
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
              User Information
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    autoComplete="off"
                    value={newUserName}
                    onChange={e => setNewUserName(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    autoComplete="off"
                    value={newUserEmail}
                    onChange={e => setNewUserEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Password
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    autoComplete="off"
                    value={newUserPassword}
                    onChange={e => setNewUserPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Phone
                  </label>
                  {/* <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    autoComplete="off"
                    value={newUserPhone}
                    onChange={e => setNewUserPhone(e.target.value)}
                  /> */}
                  <PhoneInput
                    defaultCountry="DE"
                    value={newUserPhone}
                    onChange={setNewUserPhone} />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    User group
                  </label>
                  <Select
                    name="enterprise"
                    value={newUserUserGroups.value}
                    options={userGroupOptions}
                    onChange={e => setNewUserUserGroups(e.value)}
                  />
                </div>
              </div>


              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Language
                  </label>
                  <Select
                    name="language"
                    value={newUserLanguage}
                    options={[{ value: "en", label: "English" }, { value: "pt", label: "Portuguese" }]}
                    onChange={e => setNewUserLanguage(e)}
                  />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4" >
                <div className="relative w-full mb-3">
                  {/* <div className="site-manager" hidden={!(isSiteManager === "1")}> */}
                  <label htmlFor="field1">
                    <span>PACK4 <span className="required">*</span></span>
                    <Select className="dropdown-selection"
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      defaultValue={{ value: "4", label: "Pack4" }}
                      options={[{ value: "4", label: "Pack4" }, { value: "1", label: "Pack1" }]}
                      onChange={(selectedOption) => {
                        setPackVersion(selectedOption);
                        console.log(selectedOption)
                      }}
                    />
                  </label>
                </div>
              </div>


            </div>

            <hr className="mt-6 border-b-1 border-gray-400" />
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
              Manager section
            </h6>
            {managerSection}

            <hr className="mt-6 border-b-1 border-gray-400" />
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
              Operator section
            </h6>
            {operatorSection}

            <hr className="mt-6 border-b-1 border-gray-400" />
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
              Viewer section
            </h6>
            {viewerSection}

            <hr className="mt-6 border-b-1 border-gray-400" />
            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
              Overviewer section
            </h6>
            {overviewerSection}


            <hr className="mt-6 border-b-1 border-gray-400" />



            <div className="flex flex-wrap">
              <div className="relative w-3/12 mb-8">
                <div className="form-buttons flex mb-2">
                  <button type="reset" name="cancelButton" className="cancelButton">Cancel</button>
                </div>
              </div>
              <div className="relative w-3/12 mb-8">
                <div className="form-buttons flex mb-8">
                  <button type="submit" onClick={handleRegisterUser} name="submitButton" className="submitButton">Save</button>
                </div>
              </div>
            </div>


          </form>
        </div>
      </div>
    </>
  );
}
